import React from "react";
//@ts-ignore
import { Card, CardBody } from "shards-react";
import { WelcomeCardContainer } from "./WelcomeCardStyles";

const WelcomeCard = () => {
  return (
    <div>
      <WelcomeCardContainer>
        <Card>
          <CardBody>
            <h3>Welcome to Iambic.dev</h3>
            {/* <canvas className='textCanvas' /> */}
            <p>
              All the poems on this site are generated by an AI. Register or
              Login to be able to post, the above poem and vote on plenty
              others!{" "}
            </p>
          </CardBody>
        </Card>
      </WelcomeCardContainer>
    </div>
  );
};

export default WelcomeCard;
