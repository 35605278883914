import React, { Component } from 'react';
import { motion } from 'framer-motion';
import { SVGFlowerContainer } from './styles';

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    pathOffset: 2766,
    pathSpacing: 1
  },
  visible: {
    opacity: 1,
    pathLength: 1111,
    pathOffset: 60000,
    pathSpacing: 1212
  }
};

export default class Flower extends Component {
  // const [flowerOpaque, setFlowerOpaque] = useState(true);

  state = {
    flowerOpaque1: true,
    flowerOpaque2: true,
    flowerOpaque3: true
  };
  componentDidMount() {
    this.timer1 = setInterval(
      () => this.setState({ flowerOpaque1: !this.state.flowerOpaque1 }),
      5000
    );
    this.timer2 = setInterval(
      () => this.setState({ flowerOpaque2: !this.state.flowerOpaque2 }),
      1000
    );
    this.timer3 = setInterval(
      () => this.setState({ flowerOpaque3: !this.state.flowerOpaque3 }),
      1500
    );
  }

  render() {
    const { flowerOpaque1, flowerOpaque2, flowerOpaque3 } = this.state;
    return (
      <div>
        <SVGFlowerContainer>
          <motion.svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 168.8 301.52'
          >
            <title>flower1</title>
            <g>
              <motion.path
                variants={icon}
                initial='visible'
                animate='visible'
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M231.08,583.32a31.93,31.93,0,0,0-4,.84c3.57,44.12,27.93,90.13,42.82,127.37,10.08,25.22,33.6,54.7,27.1,79.94-21.89-12.84-23.12-49.87-30.74-75.19-2.75-9.14-7.74-16.84-11.61-25.44-3.78-8.41-7.43-15.83-10.66-24.38-7.92-21-17-43.69-19.94-66.5-.82-6.25-1.83-8.5-3-14.3a24.68,24.68,0,0,1-8.34,2.48v-4.82Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f5ecbc', fillRule: 'evenodd' }}
              />

              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 1, ease: 'easeInOut' },
                  fill: { duration: 1, ease: [1, 1, 0.8, 2] }
                }}
                d='M256.81,579.11a10.21,10.21,0,1,0-10.2-10.21A10.23,10.23,0,0,0,256.81,579.11Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f1e29b', fillRule: 'evenodd' }}
              />
              <g>
                <motion.path
                  variants={icon}
                  initial='hidden'
                  animate={flowerOpaque1 ? 'visible' : 'hidden'}
                  transition={{
                    default: { duration: 2, ease: 'easeInOut' },
                    fill: { duration: 2, ease: [1, 1, 0.8, 2] }
                  }}
                  d='M219.44,595.05a27,27,0,1,0-27-27A27.1,27.1,0,0,0,219.44,595.05Z'
                  transform='translate(-131.82 -489.96)'
                  style={{ fill: '#f4db71', fillRule: 'evenodd' }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 3, ease: 'easeInOut' },
                      fill: { duration: 3, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M227,601.29a21.46,21.46,0,0,0,7.36-1.27l3.63-1.57a32,32,0,0,0,9.77-7.93c6.09-6.93,9-16.79,8.51-25.92a37.12,37.12,0,0,0-24.42-32.77,35.6,35.6,0,0,0-14.42-2.13c-9.48.82-19,5-25.15,12.45-6,7.2-9.76,16.7-8.93,26.2a37.31,37.31,0,0,0,10.38,23,34.75,34.75,0,0,0,11.18,7.82,30.14,30.14,0,0,0,6.55,2.3,40.09,40.09,0,0,0,9.46.89c.32,0-1.87-5.26-1.82-5.13-.56-1.32-1.15-2.62-1.78-3.9a1.53,1.53,0,0,0-.73-.93,29.21,29.21,0,0,1-9.28-3.06,24,24,0,0,1-7-6.28,24.88,24.88,0,0,1-5.08-17.84,24.41,24.41,0,0,1,9-16.57,24.84,24.84,0,0,1,34.91,3.81,25.67,25.67,0,0,1-2.27,34.41,24,24,0,0,1-8,5.13,28.34,28.34,0,0,1-11,1.59,1.72,1.72,0,0,0,.76,1q1.72,1.58,3.52,3.09t3.63,3A1.74,1.74,0,0,0,227,601.29Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: '0.10910128185966073' }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 3, ease: 'easeInOut' },
                      fill: { duration: 3, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M228.13,603.34a21.28,21.28,0,0,0,7.41-1.32l3.65-1.64a32,32,0,0,0,9.73-8.16c6-7.05,8.78-17,8.07-26.12a37.27,37.27,0,0,0-24.56-32.18,35.84,35.84,0,0,0-14.21-2.14c-9.4.73-18.83,4.73-25.11,12s-10.13,16.54-9.47,26.08a37.4,37.4,0,0,0,10.24,23.54,34.88,34.88,0,0,0,11.43,8,30.44,30.44,0,0,0,6.72,2.35,40.09,40.09,0,0,0,9.71.86c.29,0-2.09-5.59-2-5.45q-.92-2.1-1.94-4.16a1.71,1.71,0,0,0-.77-1,31.34,31.34,0,0,1-10-2.94,25.48,25.48,0,0,1-7.74-6.49,26.17,26.17,0,0,1-5.75-19.06A25.85,25.85,0,0,1,203,547.87a26.16,26.16,0,0,1,36.63,3.66,26.88,26.88,0,0,1,6,18.92A26.57,26.57,0,0,1,238,587.3a24.63,24.63,0,0,1-8.06,5.46,27.4,27.4,0,0,1-11,1.74,2,2,0,0,0,.76,1.14c1.18,1.21,2.37,2.39,3.6,3.55s2.44,2.32,3.7,3.44A2,2,0,0,0,228.13,603.34Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: '0.10910128185966073' }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 2, ease: 'easeInOut' },
                      fill: { duration: 2, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M230.08,603a40,40,0,0,0,14.39-6.54A38.66,38.66,0,0,0,253.6,586a39.62,39.62,0,0,0,5.55-18.84c.87-16.37-9.84-32.06-24.77-38.25a39.59,39.59,0,0,0-54.26,31.19,39.53,39.53,0,0,0,11.77,33.19A38.84,38.84,0,0,0,207,602.51c5.17,1.72,9.93,1.93,15.27,1.9.71,0,.48-1.22.05-1.22l-15-1.9-4.53-1.84a35,35,0,0,1-9.79-7.16,36.61,36.61,0,0,1-10.76-29.22,33.74,33.74,0,0,1,3.38-11.76,36.41,36.41,0,0,1,15-15.9,40.74,40.74,0,0,1,18.31-4.91,36.76,36.76,0,0,1,19.78,5.57,37.38,37.38,0,0,1,8.6,7.1L249,589.08l-2.91,3.34a28.21,28.21,0,0,1-8.74,6,21.28,21.28,0,0,0-3.37,1.7,14.77,14.77,0,0,1-4.47,1.91C229,602.26,229.73,603.11,230.08,603Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 1, ease: 'easeInOut' },
                      fill: { duration: 1, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M229.17,608.2a28.41,28.41,0,0,0,3.89-.53,32.07,32.07,0,0,0,7.64-3,34.92,34.92,0,0,0,12.89-12.93,40.47,40.47,0,0,0,3-34.57c-6.21-16.43-22.94-27.32-40.46-26a39.69,39.69,0,0,0-29.37,62.13,40.22,40.22,0,0,0,15.22,12.92,39.74,39.74,0,0,0,20.18,3.89c.46,0,.13-3.17-.61-3.19a61.18,61.18,0,0,1-9.93-.87,36.1,36.1,0,0,1-11-4.34,37,37,0,0,1-17.8-23.84l4.81-28.1,2.77-3.93a38.38,38.38,0,0,1,7.1-6.67c12.35-8.93,32.39-8.49,44.65.67a38.28,38.28,0,0,1,14.54,37.5,38.89,38.89,0,0,1-8,16.73c-5.23,6.48-13,10.14-21.15,11.38C226.67,605.58,228.63,608.28,229.17,608.2Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: '0.10910128185966073' }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M227.44,603.84a21.1,21.1,0,0,0,7.37-1.36l3.61-1.65a32,32,0,0,0,9.6-8.16,35.84,35.84,0,0,0,6.25-11.84A37,37,0,0,0,231.81,535a35.46,35.46,0,0,0-14-2.23c-9.34.63-18.75,4.53-25,11.66s-10.26,16.47-9.62,26a37.2,37.2,0,0,0,10.25,23.58,34.4,34.4,0,0,0,11.46,8,31.2,31.2,0,0,0,6.72,2.3,41.8,41.8,0,0,0,9.67.79c.29,0-2.08-6.84-2-6.68q-.93-2.6-2-5.15a2.33,2.33,0,0,0-.78-1.27,28.66,28.66,0,0,1-9.25-2.94,23.45,23.45,0,0,1-7-6.23A24.33,24.33,0,0,1,195.31,565a23.85,23.85,0,0,1,9.35-16.2,24.37,24.37,0,0,1,17.81-4.68,24,24,0,0,1,16,9.2,25.1,25.1,0,0,1,5.08,17.63,24.8,24.8,0,0,1-7.51,15.64,23.66,23.66,0,0,1-7.78,5,27.25,27.25,0,0,1-10.71,1.51,2.46,2.46,0,0,0,.82,1.33q1.88,2.19,3.84,4.31t4,4.2A2.38,2.38,0,0,0,227.44,603.84Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M227.3,602a21.49,21.49,0,0,0,7.36-1.3l3.64-1.59a32.09,32.09,0,0,0,9.74-8c6-6.94,8.91-16.79,8.31-25.88A36.91,36.91,0,0,0,232,532.92a35.51,35.51,0,0,0-14.26-2.12c-9.4.77-18.83,4.86-25,12.17-6,7.11-9.88,16.54-9.12,26a37.1,37.1,0,0,0,10.26,23.11A34.8,34.8,0,0,0,205.06,600a30.5,30.5,0,0,0,6.59,2.31,41,41,0,0,0,9.51.88c.29,0-2.09-5.89-2-5.75-.62-1.47-1.27-2.94-1.95-4.39a1.81,1.81,0,0,0-.77-1,28.54,28.54,0,0,1-9.12-3.05,23.5,23.5,0,0,1-6.87-6.23,24.3,24.3,0,0,1-4.79-17.59,23.82,23.82,0,0,1,9.06-16.1,24.29,24.29,0,0,1,17.7-5,24,24,0,0,1,16.33,9.11,25.14,25.14,0,0,1-2.51,33.61,23.63,23.63,0,0,1-7.86,5,28.24,28.24,0,0,1-10.87,1.51,2.07,2.07,0,0,0,.83,1.15q1.88,1.81,3.83,3.55t3.93,3.44A2.08,2.08,0,0,0,227.3,602Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M228.09,605.68a20.54,20.54,0,0,0,7.29-1.45l3.7-1.68a33.16,33.16,0,0,0,9.89-8.37c6.17-7.27,9.09-17.46,8.45-26.9a38.62,38.62,0,0,0-25.5-33.75A37.3,37.3,0,0,0,217,531.36c-9.9.82-19.75,5.13-26.27,12.82-6.38,7.53-10.4,17.48-9.6,27.49a38.87,38.87,0,0,0,11,24.47,36.7,36.7,0,0,0,12,8.21,30.71,30.71,0,0,0,7,2.33,40.36,40.36,0,0,0,10,.75c.42,0-1.13-3.79-1.09-3.69-.17-.42-1-3.33-1.79-3.41a32.25,32.25,0,0,1-8.38-1.41l-3.84-1.47a31.75,31.75,0,0,1-9.9-7,32.38,32.38,0,0,1-9.08-22.34,33,33,0,0,1,7.83-21.24,32.31,32.31,0,0,1,22.2-11.3,23.35,23.35,0,0,1,6.78.11l8.54,2.33a32.09,32.09,0,0,1,10.73,7.38c6.18,6.12,9.42,15.28,9.13,23.89a32.55,32.55,0,0,1-10,22.45,31.14,31.14,0,0,1-7.77,5.34A26.88,26.88,0,0,1,223,599.46c-.26,0,2.35,3.44,2.28,3.35C225.59,603.25,227.2,605.77,228.09,605.68Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M230.3,603.83a20.92,20.92,0,0,0,7.13-1.71,32.12,32.12,0,0,0,7.48-4.18,35.42,35.42,0,0,0,3.51-3.09,38.71,38.71,0,0,0,9.51-15.92,40.93,40.93,0,0,0-.61-25.31c-3.78-11.13-13.38-20.21-24.38-24.06a39.15,39.15,0,0,0-42,63.35A40.68,40.68,0,0,0,203.06,602a42.86,42.86,0,0,0,9.69,3.2,42.13,42.13,0,0,0,10.46.56c.49,0,.07-2-.58-1.94a44.64,44.64,0,0,1-12.21-1.2,39.59,39.59,0,0,1-11-4.86c-6.82-4.33-11.78-11.2-14.6-18.7A35.15,35.15,0,0,1,183,564.31a39.67,39.67,0,0,1,2.1-9.5,37,37,0,0,1,29-23.21,39.1,39.1,0,0,1,12.21.38,36.21,36.21,0,0,1,19.84,11,36.63,36.63,0,0,1,9.57,20.43c1.67,13.23-5.33,28.81-17.12,35.07L235,600.2a20.16,20.16,0,0,1-6.46,1.73C227.77,602.06,229.74,603.91,230.3,603.83Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M228.28,605.69a20.81,20.81,0,0,0,7.35-1.4l3.64-1.69a32.57,32.57,0,0,0,9.68-8.35c6-7.19,8.64-17.15,7.94-26.38a38.47,38.47,0,0,0-12.41-25.32,38,38,0,0,0-12.1-7.35A36.08,36.08,0,0,0,218,532.89c-9.59.63-19.29,4.65-25.72,12s-10.44,17-9.8,26.8A38,38,0,0,0,193,595.93a35.66,35.66,0,0,0,11.78,8.21,31.1,31.1,0,0,0,6.91,2.34,40.66,40.66,0,0,0,9.91.77c.33,0-1.88-5.66-1.82-5.52-.57-1.42-1.16-2.83-1.8-4.22a1.65,1.65,0,0,0-.73-1,34.65,34.65,0,0,1-10.82-2.91,28.1,28.1,0,0,1-8.59-6.68,28.71,28.71,0,0,1,23.52-47.63c7.71.69,15.17,4.13,20.07,10.26a29.27,29.27,0,0,1-1.62,39.15,24.69,24.69,0,0,1-19.39,7.67,1.8,1.8,0,0,0,.6,1.17c1,1.27,2,2.52,3,3.74s2.08,2.45,3.16,3.64A1.78,1.78,0,0,0,228.28,605.69Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M227.18,602.86a21.51,21.51,0,0,0,7.36-1.32l3.62-1.62a31.7,31.7,0,0,0,9.65-8c5.94-6.94,8.74-16.75,8.1-25.79A37,37,0,0,0,231.76,534a35.55,35.55,0,0,0-14.15-2.21c-9.37.69-18.82,4.67-25.07,11.89s-10.08,16.52-9.37,26A37.17,37.17,0,0,0,193.47,593,34.62,34.62,0,0,0,204.82,601a31.13,31.13,0,0,0,6.65,2.29,41.1,41.1,0,0,0,9.58.82c.3,0-2-6.37-2-6.22q-.9-2.42-1.9-4.78a2,2,0,0,0-.76-1.17,28.32,28.32,0,0,1-9.15-3,23.29,23.29,0,0,1-6.94-6.21,24.22,24.22,0,0,1-4.82-17.7,23.74,23.74,0,0,1,9.19-16.12,24.26,24.26,0,0,1,17.73-4.82,23.89,23.89,0,0,1,16.14,9.17A25,25,0,0,1,243.65,571a24.78,24.78,0,0,1-7.59,15.62,23.55,23.55,0,0,1-7.81,5,27.68,27.68,0,0,1-10.76,1.5,2.26,2.26,0,0,0,.8,1.24q1.85,2,3.78,3.92t3.88,3.81A2.2,2.2,0,0,0,227.18,602.86Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M228.69,604.86a21,21,0,0,0,7.35-1.39l3.66-1.67a32.57,32.57,0,0,0,9.76-8.3c6-7.18,8.8-17.21,8.06-26.46a37.93,37.93,0,0,0-24.79-32.78A36.4,36.4,0,0,0,218.26,532c-9.62.68-19.28,4.73-25.72,12.11s-10.44,17-9.72,26.76A38.42,38.42,0,0,0,193.39,595a36,36,0,0,0,11.76,8.22,30.3,30.3,0,0,0,6.89,2.35,40.28,40.28,0,0,0,9.92.82c.32,0-1.89-4.93-1.83-4.8-.23-.49-1.56-4.37-2.51-4.48a26.79,26.79,0,0,1-7.63-1.46l-3.49-1.41a29,29,0,0,1-8.86-6.76,29.23,29.23,0,0,1,23.74-48.71c7.78.68,15.33,4.11,20.34,10.25a30.13,30.13,0,0,1,7,21.18,29.67,29.67,0,0,1-8.14,18.53,24.85,24.85,0,0,1-19.59,8,1.59,1.59,0,0,0,.59,1.06c1,1.12,2,2.22,3,3.28s2,2.14,3.11,3.17A1.59,1.59,0,0,0,228.69,604.86Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M229.79,608.75c1.32-.11,2.64-.28,3.94-.52a28.86,28.86,0,0,0,7.64-3.05,35.41,35.41,0,0,0,12.82-13,40.34,40.34,0,0,0-32.32-60.67,39.62,39.62,0,0,0-40.78,47.83,39.87,39.87,0,0,0,21.55,27.53,39.16,39.16,0,0,0,20.16,3.82c.49,0,.17-3-.54-3a45.44,45.44,0,0,1-12.16-1.29,37,37,0,0,1-19.69-12.77,18.86,18.86,0,0,1-5-13.42,22.36,22.36,0,0,1-3-16.49,39,39,0,0,1,19.51-27.08l4.57-2.13a22.5,22.5,0,0,1,17.48,0,19,19,0,0,1,14.72,2.71,32.26,32.26,0,0,1,6.13,4.25,47.18,47.18,0,0,1,6.61,7.44c4.85,6.78,7,15.75,6.51,24a36.73,36.73,0,0,1-8.65,21.66,42.22,42.22,0,0,1-6.48,6c-4.21,3.1-9.65,4.7-14.7,5.45C227.25,606.11,229.24,608.82,229.79,608.75Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 2, ease: 'easeInOut' },
                  fill: { duration: 2, ease: [1, 1, 0.8, 2] }
                }}
                d='M216.93,633a15.12,15.12,0,1,0-15.11-15.12A15.14,15.14,0,0,0,216.93,633Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f1e29b', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque2 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M243.31,637.15A12.35,12.35,0,1,0,231,624.8,12.38,12.38,0,0,0,243.31,637.15Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f1e29b', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque3 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 2, ease: 'easeInOut' },
                  fill: { duration: 2, ease: [1, 1, 0.8, 2] }
                }}
                d='M201.73,643.78a8.27,8.27,0,1,0-8.27-8.27A8.28,8.28,0,0,0,201.73,643.78Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f2df83', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M231.83,607.56a8.27,8.27,0,1,0-8.27-8.27A8.28,8.28,0,0,0,231.83,607.56Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f2df83', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque2 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 1, ease: 'easeInOut' },
                  fill: { duration: 1, ease: [1, 1, 0.8, 2] }
                }}
                d='M207.34,604.5a6.74,6.74,0,1,0-6.74-6.74A6.75,6.75,0,0,0,207.34,604.5Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f2df83', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque3 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 5, ease: 'easeInOut' },
                  fill: { duration: 5, ease: [1, 1, 0.8, 2] }
                }}
                d='M181.16,589.36a8.92,8.92,0,1,0-8.92-8.92A8.93,8.93,0,0,0,181.16,589.36Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f1e29b', fillRule: 'evenodd' }}
              />
              <g>
                <motion.path
                  variants={icon}
                  initial='hidden'
                  animate={flowerOpaque1 ? 'visible' : 'hidden'}
                  transition={{
                    default: { duration: 4, ease: 'easeInOut' },
                    fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                  }}
                  d='M181.66,634.84a22.28,22.28,0,1,0-22.28-22.28A22.32,22.32,0,0,0,181.66,634.84Z'
                  transform='translate(-131.82 -489.96)'
                  style={{ fill: '#5ee1f1', fillRule: 'evenodd' }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M189.17,641.09a22.05,22.05,0,0,0,9.9-2.86,29.23,29.23,0,0,0,7.31-6.36,32.24,32.24,0,0,0,7.3-22,33,33,0,0,0-10.63-22.17,33.58,33.58,0,0,0-9.39-6.1,27.34,27.34,0,0,0-6.57-1.79,22.84,22.84,0,0,0-6.8-.57,33,33,0,0,0-20.4,9.09c-6.2,5.86-10.16,15.35-9.46,23.93a33.16,33.16,0,0,0,8.71,20,30.2,30.2,0,0,0,10.51,7.35l4.12,1.37a31.71,31.71,0,0,0,8.61,1.12c.27,0-2-5.19-2-5.07-.61-1.29-1.25-2.58-1.92-3.84a1.46,1.46,0,0,0-.76-.9c-2.44-1.11-4.89-1.88-7.09-3.47a21.56,21.56,0,0,1-7.89-11,18.58,18.58,0,0,1-.54-9.06c.45-1.09.87-2.2,1.24-3.32a11,11,0,0,1,1.92-3.8,21.33,21.33,0,0,1,11-8.25c2.36-.84,6.8-1.47,9.24-.71a24.66,24.66,0,0,1,11.58,7,21.29,21.29,0,0,1,4.24,7.46c.84,2.8,1.67,6.49.84,9.34-1.33,4.56-3.29,8.72-6.77,12-4.21,4-9.73,5.15-15.36,5a1.7,1.7,0,0,0,.76,1q1.73,1.59,3.52,3.09t3.63,3A1.75,1.75,0,0,0,189.17,641.09Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M190.35,643.14c3.64-.36,6.8-1,10-3a29.4,29.4,0,0,0,7.28-6.57,32.22,32.22,0,0,0,6.88-22.25,33.09,33.09,0,0,0-10.9-21.8,33.94,33.94,0,0,0-9.29-5.93,27.1,27.1,0,0,0-6.47-1.76,23.8,23.8,0,0,0-6.63-.6,33.38,33.38,0,0,0-20.39,8.65c-6.34,5.73-10.52,15.15-10,23.75a33.21,33.21,0,0,0,8.54,20.49,30.57,30.57,0,0,0,10.78,7.62l4.24,1.41a32,32,0,0,0,8.92,1.11c.23,0-2.31-5.47-2.25-5.34q-1-2.05-2.1-4.06a1.7,1.7,0,0,0-.81-1c-2.71-1-5.47-1.76-7.9-3.39a22.91,22.91,0,0,1-5.41-5.08,23.78,23.78,0,0,1-3.54-6.7,19.85,19.85,0,0,1-.81-9.81c.5-1.23,1-2.47,1.39-3.73a12.91,12.91,0,0,1,2-4.12,23.09,23.09,0,0,1,5.08-5.36,23.82,23.82,0,0,1,6.68-3.5c2.53-.89,7.14-1.55,9.76-.78a25.75,25.75,0,0,1,12.28,7.19,22.06,22.06,0,0,1,4.55,7.75c.93,3,1.83,6.73,1.07,9.78a26.45,26.45,0,0,1-6.66,12.67,18.3,18.3,0,0,1-7.11,4.45,26.29,26.29,0,0,1-8.38,1,2,2,0,0,0,.76,1.14c1.18,1.21,2.37,2.4,3.6,3.55s2.44,2.32,3.7,3.44A2,2,0,0,0,190.35,643.14Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M192.29,642.81a16.9,16.9,0,0,0,6.09-2.39,21.2,21.2,0,0,0,5.2-3,29.06,29.06,0,0,0,7.42-7.89,35,35,0,0,0,5.12-13c2.75-15.47-5.73-31.09-19.87-37.83-9.64-4.6-21.48-4.6-30.91.7-8.66,4.87-15.31,12.87-17.58,22.65-2.3,10,.29,20.2,6.21,28.42a34.81,34.81,0,0,0,11,9.45,31.28,31.28,0,0,0,9.11,3.43,34,34,0,0,0,9.69.78c.69,0,.51-1.16.08-1.16a24.26,24.26,0,0,1-7.22-.43,18.42,18.42,0,0,1-4.59-1,31.59,31.59,0,0,1-10.31-5.19,32.31,32.31,0,0,1-12-19.43c-1.22-7-.49-14.17,3.19-20.37a33.79,33.79,0,0,1,7.5-9,33.35,33.35,0,0,1,9.9-5.61,30.92,30.92,0,0,1,11.51-2.14,40.59,40.59,0,0,1,8.48,1.33,32.68,32.68,0,0,1,17.13,11.65,34,34,0,0,1,4.87,9.17,14.13,14.13,0,0,1,1.25,6.23q2.58,12.75-7.07,24a28.25,28.25,0,0,1-6.09,5.19,24.74,24.74,0,0,0-4.74,2.59,12.08,12.08,0,0,1-3.92,1.94C191.19,642.08,191.94,642.91,192.29,642.81Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M191.39,648a34.58,34.58,0,0,0,3.51-.54,27.28,27.28,0,0,0,6.95-3.11,30.92,30.92,0,0,0,9.08-9,34.73,34.73,0,0,0,5.66-17.83A37.18,37.18,0,0,0,214,602.7c-4.1-10.84-14-18.58-25-21.28a33.06,33.06,0,0,0-17.48.71,34.62,34.62,0,0,0-24.19,28.11,34.61,34.61,0,0,0,17.47,35.32,33.26,33.26,0,0,0,9.08,3.43,34.28,34.28,0,0,0,9.89.81c.47,0,.16-3-.57-3a34.51,34.51,0,0,1-9.11-1,32.17,32.17,0,0,1-8.36-3.22,34.06,34.06,0,0,1-10-8.45,36.69,36.69,0,0,1-4.48-7.87q-11.83-30.51,42-42.57a34,34,0,0,1,13.32,9.43A32.9,32.9,0,0,1,214,607.7a32.12,32.12,0,0,1-.56,16.23A31.2,31.2,0,0,1,206,637a28.77,28.77,0,0,1-16.32,8.29C188.88,645.39,190.86,648.08,191.39,648Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M189.66,643.63a21.32,21.32,0,0,0,9.89-3A29.22,29.22,0,0,0,206.7,634a32,32,0,0,0,6.65-22,32.86,32.86,0,0,0-10.67-21.43,33.39,33.39,0,0,0-9.08-5.88,30.8,30.8,0,0,0-12.89-2.45,32.94,32.94,0,0,0-20.25,8.38c-6.41,5.67-10.67,15.09-10.18,23.69a32.87,32.87,0,0,0,8.55,20.47,30.31,30.31,0,0,0,10.81,7.56l4.23,1.36a30.44,30.44,0,0,0,8.91,1c.25,0-2.28-6.74-2.22-6.59q-1-2.55-2.11-5.06a2.22,2.22,0,0,0-.82-1.24c-2.43-1-4.87-1.76-7-3.3a21.22,21.22,0,0,1-4.82-4.78,21.63,21.63,0,0,1-3.08-6.24c-.81-2.42-1.21-6.56-.46-9a29.27,29.27,0,0,0,1.44-3.36,11.08,11.08,0,0,1,2-3.72,21.06,21.06,0,0,1,4.84-4.72,21.62,21.62,0,0,1,6.24-3c2.29-.72,6.64-1.17,8.94-.33,4.21,1.55,7.91,3.57,10.83,7a20.62,20.62,0,0,1,3.92,7.16c.76,2.72,1.47,6.21.67,9-1.27,4.38-3.1,8.31-6.45,11.5-4.1,3.89-9.4,5-14.89,4.82a2.38,2.38,0,0,0,.82,1.32q1.88,2.19,3.84,4.31t4,4.2A2.45,2.45,0,0,0,189.66,643.63Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeIn' },
                      fill: { duration: 4, ease: [12, 0, 0.8, 1] }
                    }}
                    d='M189.52,641.83a21.83,21.83,0,0,0,9.9-2.91,29.32,29.32,0,0,0,7.28-6.41,32,32,0,0,0,7.11-22,32.82,32.82,0,0,0-10.68-21.87,33.28,33.28,0,0,0-9.3-6,27.25,27.25,0,0,0-6.49-1.76,22.91,22.91,0,0,0-6.71-.58,33,33,0,0,0-20.26,8.85c-6.22,5.78-10.26,15.18-9.65,23.71a32.93,32.93,0,0,0,8.58,20,30.06,30.06,0,0,0,10.56,7.41l4.14,1.38a31.08,31.08,0,0,0,8.69,1.11c.25,0-2.26-5.8-2.2-5.67q-1-2.18-2.08-4.32a1.78,1.78,0,0,0-.8-1c-2.39-1.11-4.78-1.86-6.92-3.43a21,21,0,0,1-7.63-10.89,17.76,17.76,0,0,1-.42-8.86,33.57,33.57,0,0,0,1.31-3.23,10.57,10.57,0,0,1,1.93-3.68,20.89,20.89,0,0,1,10.83-7.85c2.29-.78,6.63-1.32,9-.54,4.32,1.45,8.1,3.45,11.11,6.93a20.66,20.66,0,0,1,4,7.28c.78,2.73,1.55,6.33.71,9.08-1.35,4.43-3.25,8.44-6.68,11.63-4.18,3.9-9.61,5-15.15,4.8a2.07,2.07,0,0,0,.83,1.15c1.25,1.21,2.52,2.4,3.82,3.55s2.6,2.32,3.94,3.44A2,2,0,0,0,189.52,641.83Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M190.31,645.47a12.64,12.64,0,0,0,4.91-.92,18,18,0,0,0,5-2.24,28.73,28.73,0,0,0,8.74-8.37,34.18,34.18,0,0,0,5.61-23.62,33.71,33.71,0,0,0-4.82-13.56,35.75,35.75,0,0,0-7.68-8.77,32.75,32.75,0,0,0-41.81.6,34.21,34.21,0,0,0-11.89,22.17,32.6,32.6,0,0,0,1.7,14.26,33.75,33.75,0,0,0,18.8,19.69l4.46,1.41a31.37,31.37,0,0,0,9.2,1c.37,0-1.51-3.69-1.45-3.58-.34-.65-1.18-3-2.11-3.22-4.73-1.19-9.33-2.42-13.36-5.32a28.78,28.78,0,0,1-9.33-11.59,28.08,28.08,0,0,1-2.18-7.83l.3-7.43.88-3.82a27.76,27.76,0,0,1,10-14q8.09-6.93,20,1.93,14.16-4.46,19.61,4.77a28.2,28.2,0,0,1,3.57,7.34,13.27,13.27,0,0,1,1,4.21,13.56,13.56,0,0,1,.06,6.08,14.53,14.53,0,0,1-.83,5.75,13.81,13.81,0,0,1-1.33,3.69,26.6,26.6,0,0,1-9.11,11.21,21,21,0,0,1-5.74,2.9,23,23,0,0,1-7.29,1c-.25,0,2.35,3.44,2.28,3.35C187.8,643,189.43,645.57,190.31,645.47Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M192.52,643.62a17,17,0,0,0,6.39-1.72l3.49-1.85a26.83,26.83,0,0,0,6.3-5.39,33.86,33.86,0,0,0,7.86-16.23,35.6,35.6,0,0,0-5.19-25.64,34.72,34.72,0,0,0-49.48-9.3,34,34,0,0,0-3.8,52,37.51,37.51,0,0,0,10,6.8,23.51,23.51,0,0,0,6.88,2.38,33.31,33.31,0,0,0,9.7.77c.85,0,.23-1.84-.17-1.85l-4.78-.06a27.54,27.54,0,0,1-6.8-1.36,29.88,29.88,0,0,1-10.15-5,32.73,32.73,0,0,1-11.44-16.78,29.46,29.46,0,0,1-1.23-11.65,34.9,34.9,0,0,1,2.24-8.94,31.81,31.81,0,0,1,15.83-16.18,37.86,37.86,0,0,1,9-2.66,28.55,28.55,0,0,1,11.39.5,33.14,33.14,0,0,1,16.74,9.65,31.66,31.66,0,0,1,8.32,24l-.63,4.24-13.15,19a25.06,25.06,0,0,1-9,3.41C190,641.87,192,643.71,192.52,643.62Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M190.5,645.48a12.5,12.5,0,0,0,5-.89,16.27,16.27,0,0,0,4.93-2.24,29.63,29.63,0,0,0,7.23-6.72,32.91,32.91,0,0,0,6.75-22.39,33.66,33.66,0,0,0-10.76-22.19,34.58,34.58,0,0,0-9.3-6.13,30.94,30.94,0,0,0-13.15-2.57,32.62,32.62,0,0,0-11.54,2.49,35,35,0,0,0-9.58,6.09c-6.63,5.86-10.92,15.59-10.46,24.47a33.83,33.83,0,0,0,8.81,21.27,31.18,31.18,0,0,0,11.17,7.8l4.37,1.41a31.89,31.89,0,0,0,9.13,1c.26,0-2.22-5.51-2.16-5.38q-1-2.07-2.05-4.08a1.64,1.64,0,0,0-.79-1c-2.94-1-6-1.74-8.67-3.39a25.4,25.4,0,0,1-6.18-5.33,26.75,26.75,0,0,1-4.22-7.16,19.33,19.33,0,0,1-1.26-5.18,19,19,0,0,1-.28-5.68,29,29,0,0,1,7.5-14.44,24.16,24.16,0,0,1,8.75-5.44l3.5-1a14,14,0,0,1,7.19,0,23,23,0,0,1,9.84,3.29A25.67,25.67,0,0,1,200.5,598a26.74,26.74,0,0,1,4.05,7.53,20.38,20.38,0,0,1,1,5.35,19.11,19.11,0,0,1,0,5.75,29,29,0,0,1-7.25,13.72,19.77,19.77,0,0,1-7.43,4.77,25,25,0,0,1-8.3,1.09,1.85,1.85,0,0,0,.6,1.17c1,1.26,2,2.51,3,3.73s2.08,2.45,3.16,3.64A1.78,1.78,0,0,0,190.5,645.48Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M189.4,642.66a21.62,21.62,0,0,0,9.88-3,29.25,29.25,0,0,0,7.21-6.47,31.93,31.93,0,0,0,6.88-21.94,32.76,32.76,0,0,0-10.62-21.62,33.47,33.47,0,0,0-9.17-5.95,30.76,30.76,0,0,0-13.05-2.42,33,33,0,0,0-20.3,8.59c-6.32,5.72-10.48,15.14-9.91,23.72a32.86,32.86,0,0,0,8.61,20.23,30.09,30.09,0,0,0,10.69,7.45l4.19,1.36a31,31,0,0,0,8.78,1.05c.26,0-2.2-6.28-2.14-6.14q-1-2.37-2-4.71a2,2,0,0,0-.8-1.14c-2.4-1.07-4.79-1.79-7-3.34a20.84,20.84,0,0,1-7.75-10.91c-.79-2.41-1.17-6.48-.43-8.93a32.35,32.35,0,0,0,1.36-3.28,11,11,0,0,1,2-3.69,21,21,0,0,1,4.76-4.73,21.54,21.54,0,0,1,6.18-3c2.28-.75,6.63-1.25,8.94-.43,4.26,1.5,8,3.53,10.95,7a20.77,20.77,0,0,1,3.94,7.22c.76,2.71,1.49,6.26.66,9-1.33,4.38-3.19,8.33-6.57,11.5-4.14,3.88-9.48,4.95-15,4.79a2.23,2.23,0,0,0,.8,1.23q1.85,2,3.78,3.92t3.88,3.81A2.27,2.27,0,0,0,189.4,642.66Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M190.91,644.65a12.89,12.89,0,0,0,5-.88,16.46,16.46,0,0,0,5-2.21,29.56,29.56,0,0,0,7.3-6.68A33,33,0,0,0,215,612.39a33.73,33.73,0,0,0-10.94-22.3,34.65,34.65,0,0,0-9.41-6.12,31.2,31.2,0,0,0-13.24-2.51A33.3,33.3,0,0,0,169.86,584a35.3,35.3,0,0,0-9.56,6.13c-6.6,5.88-10.85,15.54-10.37,24.41a32.88,32.88,0,0,0,2.62,11.6,36,36,0,0,0,6.21,9.59,31.3,31.3,0,0,0,11.14,7.81l4.36,1.43a32.19,32.19,0,0,0,9.11,1.07c.26,0-2.21-4.77-2.14-4.65-.65-1.18-1.32-2.35-2-3.49a1.37,1.37,0,0,0-.78-.8c-3-1-6.24-1.71-9-3.38a26.14,26.14,0,0,1-6.42-5.44,28,28,0,0,1-4.41-7.34,18.68,18.68,0,0,1-1.33-5.34,20.38,20.38,0,0,1-.28-5.89c.42-1.47.86-2.94,1.31-4.41a17.42,17.42,0,0,1,2.06-4.92,26.17,26.17,0,0,1,5.63-6.6,27.32,27.32,0,0,1,7.61-4.41,17.84,17.84,0,0,1,5.52-1.24,21.81,21.81,0,0,1,6-.12,29,29,0,0,1,14.61,8.08A24.93,24.93,0,0,1,205,605l.93,3.51a26.79,26.79,0,0,1,.38,7.74,29.68,29.68,0,0,1-7.19,14.12,20.12,20.12,0,0,1-7.53,5,25.27,25.27,0,0,1-8.42,1.17,1.61,1.61,0,0,0,.59,1.06c1,1.11,2,2.21,3,3.28s2,2.14,3.11,3.17A1.58,1.58,0,0,0,190.91,644.65Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M192,648.54c1.21-.13,2.4-.3,3.58-.53a24.46,24.46,0,0,0,6.94-3.1,31.39,31.39,0,0,0,11.24-13.1A35.64,35.64,0,0,0,184.58,581a35.43,35.43,0,0,0-15.08,2.25c-12.39,4.5-21,16.83-22.07,29.79a35.09,35.09,0,0,0,18,33.08,32.26,32.26,0,0,0,9.1,3.45,33.62,33.62,0,0,0,9.9.78c.49,0,.2-2.88-.51-2.9a33.62,33.62,0,0,1-9.16-.84,29.54,29.54,0,0,1-8.47-3.2,32.88,32.88,0,0,1-4.8-54.73q13.38-10.43,32.35-5,15.42,6.12,20.84,24.36a34.84,34.84,0,0,1,0,14.13,31.81,31.81,0,0,1-8.06,15.26,28,28,0,0,1-16.32,8.3C189.46,645.92,191.47,648.62,192,648.54Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#5ee1f1', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <g>
                <motion.path
                  d='M270,627.57a22,22,0,0,0,9.9-2.86,29.23,29.23,0,0,0,7.31-6.36,32.18,32.18,0,0,0,7.3-22.05,33,33,0,0,0-10.63-22.16,33.39,33.39,0,0,0-9.39-6.11,27.34,27.34,0,0,0-6.57-1.79,22.84,22.84,0,0,0-6.8-.57,33,33,0,0,0-20.4,9.09c-6.19,5.87-10.16,15.35-9.45,23.93a33.15,33.15,0,0,0,8.7,20A30.2,30.2,0,0,0,250.46,626l4.12,1.37a31.32,31.32,0,0,0,8.61,1.12c.27,0-2.05-5.19-2-5.07-.61-1.29-1.25-2.58-1.92-3.84a1.46,1.46,0,0,0-.76-.9c-2.44-1.11-4.89-1.88-7.09-3.47a21.36,21.36,0,0,1-7.88-11,18.58,18.58,0,0,1-.55-9.06c.45-1.09.87-2.2,1.25-3.32a10.75,10.75,0,0,1,1.92-3.8,21.42,21.42,0,0,1,4.72-5,21.76,21.76,0,0,1,6.28-3.28c2.35-.84,6.79-1.47,9.23-.71a24.6,24.6,0,0,1,11.58,7,21.16,21.16,0,0,1,4.24,7.45c.84,2.8,1.67,6.5.84,9.34-1.33,4.56-3.29,8.72-6.77,12-4.21,4-9.73,5.15-15.36,5a1.7,1.7,0,0,0,.76,1q1.72,1.59,3.52,3.09t3.63,3A1.71,1.71,0,0,0,270,627.57Z'
                  transform='translate(-202.68 -453.62) rotate(-6.98)'
                  style={{ fill: '#ef6348', opacity: 0.10910128185966073 }}
                  variants={icon}
                  initial='visible'
                  animate={flowerOpaque1 ? 'visible' : 'hidden'}
                  transition={{
                    default: { duration: 4, ease: 'easeInOut' },
                    fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                  }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M270,627.57a22,22,0,0,0,9.9-2.86,29.23,29.23,0,0,0,7.31-6.36,32.18,32.18,0,0,0,7.3-22.05,33,33,0,0,0-10.63-22.16,33.39,33.39,0,0,0-9.39-6.11,27.34,27.34,0,0,0-6.57-1.79,22.84,22.84,0,0,0-6.8-.57,33,33,0,0,0-20.4,9.09c-6.19,5.87-10.16,15.35-9.45,23.93a33.15,33.15,0,0,0,8.7,20A30.2,30.2,0,0,0,250.46,626l4.12,1.37a31.32,31.32,0,0,0,8.61,1.12c.27,0-2.05-5.19-2-5.07-.61-1.29-1.25-2.58-1.92-3.84a1.46,1.46,0,0,0-.76-.9c-2.44-1.11-4.89-1.88-7.09-3.47a21.36,21.36,0,0,1-7.88-11,18.58,18.58,0,0,1-.55-9.06c.45-1.09.87-2.2,1.25-3.32a10.75,10.75,0,0,1,1.92-3.8,21.42,21.42,0,0,1,4.72-5,21.76,21.76,0,0,1,6.28-3.28c2.35-.84,6.79-1.47,9.23-.71a24.6,24.6,0,0,1,11.58,7,21.16,21.16,0,0,1,4.24,7.45c.84,2.8,1.67,6.5.84,9.34-1.33,4.56-3.29,8.72-6.77,12-4.21,4-9.73,5.15-15.36,5a1.7,1.7,0,0,0,.76,1q1.72,1.59,3.52,3.09t3.63,3A1.71,1.71,0,0,0,270,627.57Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M271.16,629.62c3.64-.36,6.8-1,10-3a29.23,29.23,0,0,0,7.28-6.57,32.22,32.22,0,0,0,6.88-22.25,33.09,33.09,0,0,0-10.9-21.8,33.94,33.94,0,0,0-9.29-5.93,27.1,27.1,0,0,0-6.47-1.76,23.8,23.8,0,0,0-6.63-.6,33.33,33.33,0,0,0-20.38,8.65c-6.35,5.73-10.53,15.15-10,23.75a33.14,33.14,0,0,0,8.53,20.49,30.57,30.57,0,0,0,10.78,7.62l4.24,1.41a32,32,0,0,0,8.92,1.11c.23,0-2.31-5.47-2.24-5.34q-1-2.05-2.11-4.06a1.7,1.7,0,0,0-.81-1c-2.71-1-5.47-1.76-7.9-3.39a23.1,23.1,0,0,1-5.41-5.08,23.78,23.78,0,0,1-3.54-6.7,19.85,19.85,0,0,1-.81-9.81c.5-1.23,1-2.47,1.39-3.73a12.73,12.73,0,0,1,2-4.12,23,23,0,0,1,5.07-5.36,23.66,23.66,0,0,1,6.68-3.49c2.53-.9,7.14-1.56,9.76-.79a25.7,25.7,0,0,1,12.28,7.19,21.91,21.91,0,0,1,4.55,7.75c.93,3,1.83,6.74,1.07,9.78a26.45,26.45,0,0,1-6.66,12.67,18.22,18.22,0,0,1-7.1,4.45,26.38,26.38,0,0,1-8.39,1,2,2,0,0,0,.76,1.14c1.18,1.21,2.37,2.4,3.6,3.56s2.44,2.32,3.7,3.43A2,2,0,0,0,271.16,629.62Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M273.1,629.29a16.9,16.9,0,0,0,6.09-2.39,21.2,21.2,0,0,0,5.2-3,28.91,28.91,0,0,0,7.42-7.89,35,35,0,0,0,5.12-13c2.75-15.47-5.73-31.09-19.87-37.83-9.64-4.6-21.48-4.6-30.91.7-8.66,4.87-15.31,12.87-17.58,22.66-2.3,9.94.29,20.19,6.22,28.41a34.67,34.67,0,0,0,11,9.45,31.39,31.39,0,0,0,9.11,3.43,34,34,0,0,0,9.69.78c.69,0,.51-1.15.08-1.16a24.26,24.26,0,0,1-7.22-.43,18.42,18.42,0,0,1-4.59-1,31.79,31.79,0,0,1-10.31-5.19,32.31,32.31,0,0,1-12-19.43c-1.22-7-.49-14.17,3.19-20.36a33.87,33.87,0,0,1,7.5-9,33.35,33.35,0,0,1,9.9-5.61,30.92,30.92,0,0,1,11.51-2.14,40.59,40.59,0,0,1,8.48,1.33,32.68,32.68,0,0,1,17.13,11.65,33.86,33.86,0,0,1,4.87,9.18,14.06,14.06,0,0,1,1.25,6.22q2.58,12.75-7.07,24a28.25,28.25,0,0,1-6.09,5.19,24.74,24.74,0,0,0-4.74,2.59,12.13,12.13,0,0,1-3.91,1.94C272,628.56,272.75,629.39,273.1,629.29Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M272.2,634.48a34.58,34.58,0,0,0,3.51-.54,27.28,27.28,0,0,0,7-3.11,30.92,30.92,0,0,0,9.08-9A34.61,34.61,0,0,0,297.4,604a37.18,37.18,0,0,0-2.6-14.78c-4.1-10.84-14-18.58-25-21.28a33.06,33.06,0,0,0-17.48.71,34.63,34.63,0,0,0-24.19,28.11A34.61,34.61,0,0,0,245.57,632a33.26,33.26,0,0,0,9.08,3.43,34.64,34.64,0,0,0,9.89.82c.47,0,.16-3-.57-3a34.1,34.1,0,0,1-9.11-1A32.17,32.17,0,0,1,246.5,629a34.06,34.06,0,0,1-10-8.45,36.69,36.69,0,0,1-4.48-7.87q-11.83-30.51,42-42.57a34.07,34.07,0,0,1,13.32,9.43,33,33,0,0,1,7.44,14.63,32.12,32.12,0,0,1-.56,16.23,31.2,31.2,0,0,1-7.38,13,28.77,28.77,0,0,1-16.32,8.29C269.69,631.87,271.67,634.56,272.2,634.48Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M270.47,630.11a21.32,21.32,0,0,0,9.89-3,29.22,29.22,0,0,0,7.15-6.57,32,32,0,0,0,6.65-22,32.89,32.89,0,0,0-10.67-21.43,33.39,33.39,0,0,0-9.08-5.88,30.8,30.8,0,0,0-12.89-2.45,32.94,32.94,0,0,0-20.25,8.38c-6.4,5.67-10.67,15.09-10.18,23.7a32.87,32.87,0,0,0,8.55,20.46,30.31,30.31,0,0,0,10.81,7.56l4.23,1.36a30.44,30.44,0,0,0,8.91,1c.25,0-2.28-6.74-2.22-6.58q-1-2.56-2.11-5.07a2.22,2.22,0,0,0-.82-1.24c-2.43-1-4.86-1.75-7.05-3.3a21,21,0,0,1-4.81-4.78,21.4,21.4,0,0,1-3.09-6.24c-.8-2.42-1.21-6.56-.45-9a31.08,31.08,0,0,0,1.43-3.36,11.08,11.08,0,0,1,2-3.72,21.26,21.26,0,0,1,4.84-4.72,21.74,21.74,0,0,1,6.24-3c2.29-.72,6.64-1.17,8.94-.33,4.22,1.55,7.91,3.57,10.83,7a20.42,20.42,0,0,1,3.92,7.17c.76,2.71,1.47,6.2.67,8.95-1.27,4.38-3.1,8.31-6.45,11.5-4.1,3.89-9.4,5-14.89,4.82a2.5,2.5,0,0,0,.82,1.33c1.25,1.45,2.53,2.89,3.84,4.3s2.61,2.82,3.95,4.2A2.45,2.45,0,0,0,270.47,630.11Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M270.33,628.32a22,22,0,0,0,9.9-2.92,29.12,29.12,0,0,0,7.28-6.41,32,32,0,0,0,7.11-22,32.82,32.82,0,0,0-10.68-21.87,33.28,33.28,0,0,0-9.3-6,27.13,27.13,0,0,0-6.49-1.76,22.91,22.91,0,0,0-6.71-.58,33,33,0,0,0-20.26,8.86c-6.22,5.77-10.26,15.17-9.64,23.7a32.88,32.88,0,0,0,8.58,20,29.93,29.93,0,0,0,10.55,7.41l4.14,1.38a31.15,31.15,0,0,0,8.7,1.11c.24,0-2.27-5.8-2.21-5.67q-1-2.18-2.08-4.32a1.81,1.81,0,0,0-.8-1c-2.39-1.11-4.78-1.86-6.92-3.43a21,21,0,0,1-7.63-10.88,17.81,17.81,0,0,1-.42-8.87,31.18,31.18,0,0,0,1.31-3.23,10.47,10.47,0,0,1,1.94-3.67,21.09,21.09,0,0,1,4.67-4.76,21.31,21.31,0,0,1,6.15-3.1c2.29-.78,6.63-1.32,9-.54,4.32,1.45,8.1,3.45,11.11,6.93a20.66,20.66,0,0,1,4,7.28c.78,2.73,1.55,6.33.71,9.09-1.35,4.42-3.25,8.43-6.68,11.62-4.18,3.9-9.61,5-15.15,4.8a2.07,2.07,0,0,0,.83,1.15c1.25,1.21,2.52,2.4,3.83,3.55s2.59,2.32,3.93,3.44A2.1,2.1,0,0,0,270.33,628.32Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M271.12,632A12.64,12.64,0,0,0,276,631a18.27,18.27,0,0,0,5-2.23,28.88,28.88,0,0,0,8.74-8.38,34.18,34.18,0,0,0,5.61-23.62,33.71,33.71,0,0,0-4.82-13.56,35.75,35.75,0,0,0-7.68-8.77,32.75,32.75,0,0,0-41.81.6,34.16,34.16,0,0,0-11.88,22.18,32.57,32.57,0,0,0,1.69,14.25,33.81,33.81,0,0,0,18.81,19.69l4.45,1.41a31.37,31.37,0,0,0,9.2,1c.37,0-1.51-3.69-1.45-3.58-.34-.65-1.18-3-2.11-3.22-4.73-1.19-9.33-2.42-13.36-5.31a28.88,28.88,0,0,1-9.33-11.6,28,28,0,0,1-2.18-7.83l.3-7.43.88-3.82a27.92,27.92,0,0,1,10-14q8.1-6.93,20,1.93,14.18-4.46,19.61,4.77a28.2,28.2,0,0,1,3.57,7.34,13.27,13.27,0,0,1,1,4.21,13.56,13.56,0,0,1,.06,6.08,14.53,14.53,0,0,1-.83,5.75,13.68,13.68,0,0,1-1.33,3.69A26.53,26.53,0,0,1,279,621.78a20.81,20.81,0,0,1-5.74,2.9,23,23,0,0,1-7.29,1.05c-.25,0,2.35,3.44,2.28,3.35C268.61,629.51,270.24,632.05,271.12,632Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M273.33,630.1a17,17,0,0,0,6.39-1.72l3.49-1.85a26.83,26.83,0,0,0,6.3-5.39,33.86,33.86,0,0,0,7.86-16.23,35.6,35.6,0,0,0-5.19-25.64,34.53,34.53,0,0,0-59.2,1.65A33.78,33.78,0,0,0,238.9,622a37.51,37.51,0,0,0,10,6.8,23.51,23.51,0,0,0,6.88,2.38,33.31,33.31,0,0,0,9.7.77c.85,0,.23-1.84-.17-1.85l-4.78-.06a27.54,27.54,0,0,1-6.8-1.36,29.88,29.88,0,0,1-10.15-5,32.73,32.73,0,0,1-11.44-16.78,29.46,29.46,0,0,1-1.23-11.65,35.31,35.31,0,0,1,2.24-8.94,31.75,31.75,0,0,1,15.83-16.17,37.43,37.43,0,0,1,9-2.67,28.37,28.37,0,0,1,11.39.51A33.05,33.05,0,0,1,286,577.56a31.66,31.66,0,0,1,8.32,24l-.62,4.24-13.16,19a25.06,25.06,0,0,1-9.05,3.41C270.79,628.35,272.78,630.2,273.33,630.1Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M271.31,632a12.5,12.5,0,0,0,5-.89,16.27,16.27,0,0,0,4.93-2.24,29.63,29.63,0,0,0,7.23-6.72,32.91,32.91,0,0,0,6.75-22.39,33.62,33.62,0,0,0-10.76-22.18,34.38,34.38,0,0,0-9.3-6.14A30.94,30.94,0,0,0,262,568.83a32.62,32.62,0,0,0-11.54,2.49,35.19,35.19,0,0,0-9.58,6.09c-6.63,5.87-10.92,15.59-10.46,24.48a33.88,33.88,0,0,0,8.81,21.26,31.05,31.05,0,0,0,11.17,7.8l4.37,1.41a31.93,31.93,0,0,0,9.13,1c.26,0-2.22-5.51-2.16-5.38q-1-2.07-2-4.08a1.69,1.69,0,0,0-.8-1c-2.94-1-6-1.74-8.67-3.39a25.4,25.4,0,0,1-6.18-5.33,26.75,26.75,0,0,1-4.22-7.16,19.33,19.33,0,0,1-1.26-5.18,19,19,0,0,1-.28-5.68,29,29,0,0,1,7.51-14.44,24,24,0,0,1,8.74-5.44l3.51-1a14,14,0,0,1,7.18,0,23,23,0,0,1,9.84,3.29,25.67,25.67,0,0,1,6.27,5.76,26.74,26.74,0,0,1,4.05,7.53,20.46,20.46,0,0,1,1.05,5.35,19.11,19.11,0,0,1,0,5.75,29,29,0,0,1-7.25,13.72,19.69,19.69,0,0,1-7.43,4.77,25,25,0,0,1-8.3,1.09,1.85,1.85,0,0,0,.6,1.17c1,1.27,2,2.51,3,3.73s2.08,2.45,3.16,3.64A1.78,1.78,0,0,0,271.31,632Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M270.21,629.14a21.62,21.62,0,0,0,9.88-3,29.25,29.25,0,0,0,7.21-6.47,31.92,31.92,0,0,0,6.88-21.93,32.76,32.76,0,0,0-10.62-21.63,33.7,33.7,0,0,0-9.17-5.95,30.76,30.76,0,0,0-13.05-2.42,33,33,0,0,0-20.29,8.59c-6.33,5.72-10.49,15.14-9.92,23.72a32.86,32.86,0,0,0,8.61,20.23,30.09,30.09,0,0,0,10.69,7.45l4.19,1.36a31,31,0,0,0,8.78,1c.26,0-2.2-6.28-2.14-6.14q-1-2.37-2-4.71a2.08,2.08,0,0,0-.8-1.14c-2.4-1.07-4.79-1.79-7-3.34a21.23,21.23,0,0,1-4.72-4.74,21.49,21.49,0,0,1-3-6.17,17.69,17.69,0,0,1-.43-8.93,32.42,32.42,0,0,0,1.37-3.28,10.8,10.8,0,0,1,2-3.69,21.15,21.15,0,0,1,4.76-4.73,21.54,21.54,0,0,1,6.18-3c2.28-.75,6.63-1.25,8.94-.43,4.26,1.51,8,3.53,11,7a20.61,20.61,0,0,1,3.94,7.22c.76,2.72,1.49,6.26.66,9-1.33,4.38-3.19,8.33-6.57,11.5-4.14,3.88-9.48,4.95-15,4.79a2.23,2.23,0,0,0,.8,1.23q1.85,2,3.78,3.92t3.88,3.81A2.27,2.27,0,0,0,270.21,629.14Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M271.72,631.13a12.89,12.89,0,0,0,5-.88,16.46,16.46,0,0,0,5-2.21,29.56,29.56,0,0,0,7.3-6.68,32.92,32.92,0,0,0,6.89-22.49,33.73,33.73,0,0,0-10.94-22.3,34.65,34.65,0,0,0-9.41-6.12,31.2,31.2,0,0,0-13.24-2.51,33.08,33.08,0,0,0-11.57,2.56,35.34,35.34,0,0,0-9.56,6.12c-6.6,5.88-10.85,15.54-10.36,24.41a32.86,32.86,0,0,0,2.61,11.6,36,36,0,0,0,6.21,9.59A31.18,31.18,0,0,0,250.71,630l4.36,1.44a32.55,32.55,0,0,0,9.11,1.06c.26,0-2.21-4.77-2.14-4.65-.65-1.18-1.32-2.35-2-3.49a1.4,1.4,0,0,0-.78-.8c-3.05-1-6.24-1.71-9-3.38a26.14,26.14,0,0,1-6.42-5.44,27.78,27.78,0,0,1-4.41-7.34,18.68,18.68,0,0,1-1.33-5.34,20.38,20.38,0,0,1-.28-5.89c.42-1.47.86-2.94,1.31-4.41a17.42,17.42,0,0,1,2.06-4.92,26.15,26.15,0,0,1,5.63-6.59,27.26,27.26,0,0,1,7.62-4.42,17.71,17.71,0,0,1,5.51-1.24,21.81,21.81,0,0,1,6-.12,29,29,0,0,1,14.61,8.08,24.93,24.93,0,0,1,5.27,8.89l.93,3.51a26.45,26.45,0,0,1,.38,7.74,29.68,29.68,0,0,1-7.19,14.12,20.12,20.12,0,0,1-7.53,5A25.27,25.27,0,0,1,264,623a1.61,1.61,0,0,0,.59,1.06c1,1.12,2,2.21,3,3.28s2.05,2.14,3.11,3.17A1.58,1.58,0,0,0,271.72,631.13Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M272.81,635c1.21-.13,2.4-.3,3.58-.53a24.19,24.19,0,0,0,6.94-3.1,31.39,31.39,0,0,0,11.24-13.1,35.64,35.64,0,0,0-29.18-50.77,35.43,35.43,0,0,0-15.08,2.25c-12.39,4.5-21,16.83-22.07,29.79a35.09,35.09,0,0,0,18,33.08,32,32,0,0,0,9.1,3.45,33.62,33.62,0,0,0,9.9.78c.49,0,.2-2.88-.51-2.9a33.62,33.62,0,0,1-9.16-.84,29.54,29.54,0,0,1-8.47-3.2,32.87,32.87,0,0,1-4.8-54.72q13.4-10.44,32.35-5,15.42,6.12,20.84,24.36a34.89,34.89,0,0,1,0,14.14A31.82,31.82,0,0,1,287.42,624a28,28,0,0,1-16.31,8.3C270.28,632.4,272.28,635.1,272.81,635Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fdd5c8', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <g>
                <motion.path
                  variants={icon}
                  initial='hidden'
                  animate={flowerOpaque1 ? 'visible' : 'hidden'}
                  transition={{
                    default: { duration: 4, ease: 'easeInOut' },
                    fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                  }}
                  d='M156.35,593.46a13.15,13.15,0,1,0-13.14-13.15A13.17,13.17,0,0,0,156.35,593.46Z'
                  transform='translate(-131.82 -489.96)'
                  style={{ fill: '#e3c4e8', fillRule: 'evenodd' }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M163.86,599.71a11.37,11.37,0,0,0,5.49-1.61,15.84,15.84,0,0,0,5-4.46A22,22,0,0,0,178.88,579a23.34,23.34,0,0,0-7-15.11c-4.1-3.88-9.41-6.59-15.18-6.52a21.71,21.71,0,0,0-15.11,6.68,20.92,20.92,0,0,0-6,14.65A21.46,21.46,0,0,0,142,593.26a23.88,23.88,0,0,0,6.43,4.41A21.79,21.79,0,0,0,156,599.3c.41.06-.6-5.32-.58-5.2-.07-.4-.47-4.82-1.32-5.06a3.09,3.09,0,0,1-2.4-1.37,4.77,4.77,0,0,1-2.7-2.13,17.54,17.54,0,0,0-1.6-3.43,6.3,6.3,0,0,1,.1-4.09,3.41,3.41,0,0,1,1.23-2.69,5.6,5.6,0,0,1,2.32-2.91,38.83,38.83,0,0,0,3.65-1.69,7,7,0,0,1,4.35.11,4.1,4.1,0,0,1,2.89,1.47,6.18,6.18,0,0,1,3.05,2.47,41.26,41.26,0,0,0,1.85,3.85,7.74,7.74,0,0,1,.09,4.57,5.11,5.11,0,0,1-1.42,3.3,6.94,6.94,0,0,1-2.39,3.14,40.45,40.45,0,0,1-3.76,1.94,9.76,9.76,0,0,1-4.52.46,1.72,1.72,0,0,0,.76,1c1.15,1,2.32,2.09,3.52,3.09s2.4,2,3.63,3A1.75,1.75,0,0,0,163.86,599.71Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M165,601.75a11.09,11.09,0,0,0,5.53-1.68,15.17,15.17,0,0,0,5-4.65,22.09,22.09,0,0,0,4.22-14.89c-1-11.2-10.85-21.25-22.37-21.16-11.05.09-21.29,9.41-21.44,20.66A21.39,21.39,0,0,0,142,595a24.3,24.3,0,0,0,6.57,4.75,22.83,22.83,0,0,0,7.9,1.83c.34.05-1.08-5.48-1-5.34-.37-1.38-.76-2.76-1.19-4.12a1.48,1.48,0,0,0-.59-1,5.36,5.36,0,0,1-3.21-1.78,6.94,6.94,0,0,1-3.07-2.78c-.58-1.35-1.16-2.71-1.73-4.07a8.44,8.44,0,0,1,.19-4.75,5,5,0,0,1,1.71-3.11,6.76,6.76,0,0,1,2.7-3.07c1.36-.57,2.7-1.16,4-1.78a8.26,8.26,0,0,1,4.75.1,4.89,4.89,0,0,1,3.23,1.52,7.35,7.35,0,0,1,3.39,2.69q1.05,2.09,2.11,4.17a9.07,9.07,0,0,1,.27,5,5.86,5.86,0,0,1-1.35,3.67,8,8,0,0,1-2.48,3.42,26.56,26.56,0,0,1-3.84,2.12,10,10,0,0,1-4.57.52,2,2,0,0,0,.77,1.14c1.17,1.21,2.36,2.4,3.59,3.55s2.44,2.32,3.7,3.44A1.91,1.91,0,0,0,165,601.75Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M167.13,601.39a17.79,17.79,0,0,0,5.28-2.64,26.6,26.6,0,0,0,5.09-5.48A24.73,24.73,0,0,0,181,571.81a26.09,26.09,0,0,0-5.88-10.18,25.76,25.76,0,0,0-9.55-6.74l-4.46-1.29a16.47,16.47,0,0,0-7.19-.13c-3.84.48-8.29,1.62-11.45,4-5.12,3.79-9.13,9.35-10.27,15.68A22.89,22.89,0,0,0,133.35,585a28.53,28.53,0,0,0,3.12,6.58c1.61,2.42,4.47,4.62,6.9,6.27,4.17,2.84,8.45,3.39,13.35,3.82.54,0,.52-.92,0-1l-16.13-6.58L137.44,584q-5.4-7.11-1.83-14.19a20.85,20.85,0,0,1,3.84-5.14,21.82,21.82,0,0,1,4.83-4.31,29.09,29.09,0,0,1,10.87-3.1,13.43,13.43,0,0,1,6.2.28,18.3,18.3,0,0,1,5.46,2.1,25.92,25.92,0,0,1,7.89,6.18,19.1,19.1,0,0,1,3.11,5.34,18.36,18.36,0,0,1,1.86,5.88,21.84,21.84,0,0,1-2.15,12.07c-2.45,4.59-6.29,9.08-11.06,11.33C165.83,600.75,166.71,601.53,167.13,601.39Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M166.27,606.58a9.09,9.09,0,0,0,4.32-1.37,12.73,12.73,0,0,0,4.26-3,26.41,26.41,0,0,0,5.51-8.71,23.87,23.87,0,0,0,.55-16.14c-1-3-2.37-6.5-4.38-8.9a29.52,29.52,0,0,0-9.26-7.35,23.61,23.61,0,0,0-11.67-2.66,27.47,27.47,0,0,0-11.42,3.43,20.4,20.4,0,0,0-5.52,4.24,24.91,24.91,0,0,0-6.46,12.4A25.24,25.24,0,0,0,134,592.9a23.5,23.5,0,0,0,9.07,10.6l4.15,2.24a25.8,25.8,0,0,0,9.31,1.7c.56,0,.66-2.47,0-2.54-3.77-.39-7-.79-10.31-2.58a25.1,25.1,0,0,1-8.26-6.64l-2.17-3.87q-4-7,1-14.56-1.2-9.37,6.19-13a15.62,15.62,0,0,1,8.07-4.21l21.44,5.81,3.06,3.3c5.17,6.21,6,16,2.66,23a23.56,23.56,0,0,1-5.62,7.75,19.62,19.62,0,0,1-8.4,4C164,604,165.43,606.76,166.27,606.58Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M164.35,602.25a11.06,11.06,0,0,0,5.49-1.72,14.81,14.81,0,0,0,4.87-4.71,21.87,21.87,0,0,0,4-14.74c-1.14-10.9-10.69-20.67-21.93-20.62a21.48,21.48,0,0,0-14.67,6,20.77,20.77,0,0,0-6.54,14.22,21.09,21.09,0,0,0,6.06,14.86,25.35,25.35,0,0,0,6.57,4.72A23.91,23.91,0,0,0,156.1,602c.37.05-.78-6.74-.75-6.58q-.43-2.58-1-5.14a2.11,2.11,0,0,0-.53-1.31,3.46,3.46,0,0,1-2.52-1.47,4.81,4.81,0,0,1-2.58-2.28,21.49,21.49,0,0,0-1.4-3.45,6.26,6.26,0,0,1,.39-4,3.38,3.38,0,0,1,1.59-2.36A4.34,4.34,0,0,1,151.5,573a11.11,11.11,0,0,0,3.33-1.35,5.63,5.63,0,0,1,3.83.33,2.91,2.91,0,0,1,2.39,1.24,5,5,0,0,1,2.87,2.09,17.74,17.74,0,0,0,1.78,3.5,6.65,6.65,0,0,1,.16,4.25,4.22,4.22,0,0,1-1.13,3.08,6.39,6.39,0,0,1-2.21,3.06,39.91,39.91,0,0,1-3.62,1.87,9.6,9.6,0,0,1-4.42.41,2.52,2.52,0,0,0,.82,1.33q1.88,2.19,3.84,4.31t4,4.2A2.38,2.38,0,0,0,164.35,602.25Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M164.21,600.45a11.24,11.24,0,0,0,5.49-1.64,15.25,15.25,0,0,0,5-4.53,21.83,21.83,0,0,0,4.39-14.65,23.23,23.23,0,0,0-7.17-14.89c-4.09-3.77-9.31-6.37-15-6.29A21.64,21.64,0,0,0,142,565a20.77,20.77,0,0,0-6.12,14.41,21.16,21.16,0,0,0,6.29,14.5,24.46,24.46,0,0,0,6.42,4.48,22.53,22.53,0,0,0,7.64,1.68c.38.05-.75-5.92-.72-5.78-.28-1.5-.59-3-.94-4.49a1.69,1.69,0,0,0-.52-1.14,2.89,2.89,0,0,1-2.26-1.39,4.27,4.27,0,0,1-2.52-2,11.65,11.65,0,0,0-1.44-3.27,5.67,5.67,0,0,1,.21-3.85,3,3,0,0,1,1.25-2.41A4.72,4.72,0,0,1,151.4,573a17.05,17.05,0,0,0,3.4-1.54,6.09,6.09,0,0,1,4,.17,3.41,3.41,0,0,1,2.62,1.34,5.36,5.36,0,0,1,2.9,2.27,23.37,23.37,0,0,0,1.76,3.63,7,7,0,0,1,.07,4.35,4.55,4.55,0,0,1-1.33,3.11,6.43,6.43,0,0,1-2.3,3q-1.8,1-3.69,1.86a9.86,9.86,0,0,1-4.48.41,2.07,2.07,0,0,0,.83,1.15q1.88,1.82,3.83,3.55t3.93,3.44A2.08,2.08,0,0,0,164.21,600.45Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M165,604.09a14.84,14.84,0,0,0,5.51-1.8,19.06,19.06,0,0,0,5.07-4.82,24,24,0,0,0,4.62-15.29,25.49,25.49,0,0,0-7.3-16.4c-4.09-4-10.05-7.16-15.91-7.06A24.64,24.64,0,0,0,141.82,564a25.41,25.41,0,0,0-5.66,6.77,21.36,21.36,0,0,0-2.73,8.28,22.68,22.68,0,0,0,5,17,24.55,24.55,0,0,0,6.69,5.61,10.05,10.05,0,0,0,4,1.75,17.79,17.79,0,0,0,6.43,1.18c.41.05-.8-3.52-.77-3.42-.18-.53-.67-3-1.47-3.17a13.34,13.34,0,0,1-5.53-2.17L145,594a18,18,0,0,1-5.63-8.28,19.72,19.72,0,0,1-1-7.31l1.91-5.94a19.07,19.07,0,0,1,4.2-5.48q16.36-8.76,24.93,1.09a17.87,17.87,0,0,1,5,9.8c.64,3.57.6,6.9-1,10.2a17.39,17.39,0,0,1-5.3,6.87,14.91,14.91,0,0,1-3.36,2.07,10.94,10.94,0,0,1-4.89.83c-.28,0,2.36,3.45,2.28,3.35C162.45,601.6,164.17,604.24,165,604.09Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M167.22,602.24a16.15,16.15,0,0,0,5.46-2.09,19.43,19.43,0,0,0,4.06-3.78,24.24,24.24,0,0,0,5.79-15.64,26.14,26.14,0,0,0-8.23-19.16,27.5,27.5,0,0,0-7.89-5.11l-4.46-1.3a15.93,15.93,0,0,0-7.2-.15,28.52,28.52,0,0,0-9.41,2.86,9.26,9.26,0,0,0-3.93,2.67,13.85,13.85,0,0,0-3.24,3.42,22.54,22.54,0,0,0-5,10.5,34.49,34.49,0,0,0-.44,5,27.48,27.48,0,0,0,4.69,13.6c2.08,3.08,5.56,5.55,8.8,7.28a10.44,10.44,0,0,0,4.32,1.73,20.13,20.13,0,0,0,7,1c.54,0,.45-1.5-.14-1.56a25.76,25.76,0,0,1-8.51-1.5,27.56,27.56,0,0,1-7.22-4.71A22.69,22.69,0,0,1,135,575.14c1-5,4-9.06,7.73-12.38a10.41,10.41,0,0,1,5.78-3.16,32,32,0,0,1,9.29-1.65c9.68.39,18.38,8.22,20.62,17.44a21.24,21.24,0,0,1-.17,11.8c-2,6.09-6.52,11.34-12.89,13.15C164.64,600.56,166.69,602.37,167.22,602.24Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M165.19,604.1a10.8,10.8,0,0,0,5.5-1.77,16.29,16.29,0,0,0,4.94-4.84,22.8,22.8,0,0,0,4.06-15.06,24.33,24.33,0,0,0-7.24-15.36,22.81,22.81,0,0,0-14.93-6.59,22.36,22.36,0,0,0-15.77,6,21.91,21.91,0,0,0-7,15,22.48,22.48,0,0,0,6.2,15.85,25.18,25.18,0,0,0,6.91,5,23,23,0,0,0,8.28,1.86c.34,0-1.18-5.39-1.14-5.25-.38-1.36-.81-2.71-1.26-4.05a1.43,1.43,0,0,0-.6-1,8.33,8.33,0,0,1-4.12-2,11,11,0,0,1-3.87-3.41,14.49,14.49,0,0,1-2.6-10.93,9.31,9.31,0,0,1,2.09-4.41,11.79,11.79,0,0,1,3.66-4A14.6,14.6,0,0,1,159.78,567a8.86,8.86,0,0,1,4.27,2.23,11.39,11.39,0,0,1,3.9,3.66A15.3,15.3,0,0,1,170.36,584a7.88,7.88,0,0,1-1.65,4.15,9.71,9.71,0,0,1-2.8,3.73,25.75,25.75,0,0,1-4,2.31,10,10,0,0,1-4.56.58,1.8,1.8,0,0,0,.6,1.16c1,1.27,2,2.52,3,3.74s2.08,2.45,3.16,3.64A1.78,1.78,0,0,0,165.19,604.1Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M164.09,601.28a11.11,11.11,0,0,0,5.48-1.68,15,15,0,0,0,4.91-4.6,21.87,21.87,0,0,0,4.18-14.63,23.26,23.26,0,0,0-7.22-14.7c-4-3.69-9.17-6.23-14.75-6.19a21.46,21.46,0,0,0-14.81,6.25,20.71,20.71,0,0,0-6.35,14.33,21.07,21.07,0,0,0,6.21,14.66,25.08,25.08,0,0,0,6.51,4.59A23.36,23.36,0,0,0,156,601c.39,0-.68-6.34-.66-6.19-.26-1.62-.56-3.23-.89-4.84a1.83,1.83,0,0,0-.51-1.23,3.11,3.11,0,0,1-2.38-1.39,4.49,4.49,0,0,1-2.54-2.15,15,15,0,0,0-1.42-3.35,5.85,5.85,0,0,1,.3-3.91,3.11,3.11,0,0,1,1.41-2.37,4.49,4.49,0,0,1,2.15-2.54,13.62,13.62,0,0,0,3.35-1.44,5.9,5.9,0,0,1,3.93.27,3.15,3.15,0,0,1,2.48,1.32,5.11,5.11,0,0,1,2.85,2.17,17.9,17.9,0,0,0,1.74,3.54,6.64,6.64,0,0,1,.09,4.27,4.27,4.27,0,0,1-1.22,3.06,6.32,6.32,0,0,1-2.23,3c-1.19.67-2.4,1.29-3.64,1.85a9.56,9.56,0,0,1-4.44.41,2.22,2.22,0,0,0,.8,1.24q1.85,2,3.78,3.92t3.88,3.81A2.27,2.27,0,0,0,164.09,601.28Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M165.6,603.27a10.93,10.93,0,0,0,5.51-1.75,16.57,16.57,0,0,0,5-4.77,22.73,22.73,0,0,0,4.22-15.1A24.29,24.29,0,0,0,173,566.16a22.86,22.86,0,0,0-15.16-6.6A22.57,22.57,0,0,0,142,565.69a22,22,0,0,0-6.94,15,22.34,22.34,0,0,0,6.19,15.9,25.17,25.17,0,0,0,6.93,5,23.2,23.2,0,0,0,8.25,1.81c.34,0-1.26-4.66-1.21-4.53-.21-.6-1-4-1.92-4.28a9.06,9.06,0,0,1-4.4-2,12,12,0,0,1-4.1-3.59A15,15,0,0,1,142,577.45a15.11,15.11,0,0,1,17.86-11.1,9.46,9.46,0,0,1,4.46,2.22,12.31,12.31,0,0,1,4,3.75,15.93,15.93,0,0,1,2.69,11.49,8.51,8.51,0,0,1-1.67,4.36,10.34,10.34,0,0,1-2.87,3.89,23.49,23.49,0,0,1-4.07,2.42,10,10,0,0,1-4.62.62,1.61,1.61,0,0,0,.59,1.07c1,1.11,2,2.21,3,3.28s2,2.13,3.11,3.17A1.62,1.62,0,0,0,165.6,603.27Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M166.89,607.12a12.16,12.16,0,0,0,5.8-2.09l2.8-2.24A23.78,23.78,0,0,0,181.75,592a25.75,25.75,0,0,0-.3-14.35,26.11,26.11,0,0,0-7.7-12.21,30.53,30.53,0,0,0-8.26-5.09,25.84,25.84,0,0,0-9.35-1.5,28.92,28.92,0,0,0-9.21,2.29,19.16,19.16,0,0,0-7.87,5.54c-3.14,3.6-5.65,7.67-6.37,12.47a23.06,23.06,0,0,0,.28,9.46,27.27,27.27,0,0,0,1.57,4.67,22.7,22.7,0,0,0,7.28,9.24,20.63,20.63,0,0,0,6,3.69,26,26,0,0,0,9.31,1.72c.54,0,.57-2.36-.08-2.43a36,36,0,0,1-8.49-1.52,24.67,24.67,0,0,1-8.86-6.09,8.54,8.54,0,0,1-2.63-3.49l-1.12-2,16.83-14.59,16.82-14.58,1.81,1.42a21.67,21.67,0,0,1,4.91,4.79,28.83,28.83,0,0,1,3.72,8.46l-.32,12.91L178,594.48a21.34,21.34,0,0,1-6.1,6.95,19.62,19.62,0,0,1-7.13,3C164.55,604.5,166,607.3,166.89,607.12Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#f69f98', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M219,532.05a12.57,12.57,0,1,0-12.57-12.57A12.59,12.59,0,0,0,219,532.05Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f1e29b', fillRule: 'evenodd' }}
              />
              <g>
                <motion.path
                  variants={icon}
                  initial='hidden'
                  animate={flowerOpaque1 ? 'visible' : 'hidden'}
                  transition={{
                    default: { duration: 4, ease: 'easeInOut' },
                    fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                  }}
                  d='M241,524a10.27,10.27,0,1,0-10.27-10.27A10.28,10.28,0,0,0,241,524Z'
                  transform='translate(-131.82 -489.96)'
                  style={{ fill: '#ed1e79', fillRule: 'evenodd' }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M248.46,530.24a9.06,9.06,0,0,0,4.93-1.72,13.2,13.2,0,0,0,4.31-4.69,19.21,19.21,0,0,0,2.73-10.68,19.83,19.83,0,0,0-5.78-12.69c-3.39-3.2-7.77-5.94-12.6-6a20.44,20.44,0,0,0-11.32,3.78,22.71,22.71,0,0,0-4.91,6.12,15.15,15.15,0,0,0-2.09,7.27,19.47,19.47,0,0,0,3.74,10.72,21,21,0,0,0,5.75,4.72,15.57,15.57,0,0,0,6.93,2.08c.34,0-.08-3.63-.12-4-.13-1.2-.28-2.39-.45-3.59a10.31,10.31,0,0,0-.48-2.46c-.14-.78-.67-1.13-1.58-1a3.38,3.38,0,0,1-1.53-2.48l-.78-1.09a3.5,3.5,0,0,1,.38-3.05.9.9,0,0,0,.59-.94,2.59,2.59,0,0,1,1.34-1.84,10.65,10.65,0,0,0,2.89-1.42,4.35,4.35,0,0,1,3.41.46l-.22-.32a2.21,2.21,0,0,0,2.15,1.43,4.84,4.84,0,0,1,2,2.93c.84,1.53,1.88,3.48.64,5.22a1.78,1.78,0,0,0-.8,1.37,3.24,3.24,0,0,1-1.27,2c-1,.6-2,1.2-3.07,1.79a7.2,7.2,0,0,1-3.87.4,1.72,1.72,0,0,0,.75,1q1.72,1.59,3.53,3.09t3.62,3A1.71,1.71,0,0,0,248.46,530.24Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M249.65,532.29a9.21,9.21,0,0,0,5-1.8,13,13,0,0,0,4.28-4.92,19.41,19.41,0,0,0,2.47-10.9,20.29,20.29,0,0,0-6.19-12.59c-3.4-3.08-7.68-5.61-12.41-5.71a20.09,20.09,0,0,0-11.17,3.54,21.37,21.37,0,0,0-5,5.8,16.08,16.08,0,0,0-2.33,7.14,19.18,19.18,0,0,0,3.25,11,21,21,0,0,0,5.75,5.07,16,16,0,0,0,7.25,2.39c.22,0-.51-3.7-.59-4.07-.27-1.21-.56-2.42-.87-3.62a11.64,11.64,0,0,0-.78-2.47,3.17,3.17,0,0,0-2.23-1.72,5.68,5.68,0,0,1-2-3.26l-.64-1.46a4.37,4.37,0,0,1,1.34-4.25,3.25,3.25,0,0,1,1.37-2.1,4.17,4.17,0,0,1,2.51-1.34,4.52,4.52,0,0,1,3.34-.87,2.44,2.44,0,0,1,2.26.88,2.63,2.63,0,0,0,2,1,5.84,5.84,0,0,1,2.38,3.18c1,1.78,2,3.84,1,5.93a4.45,4.45,0,0,0-1,2.43L247.49,521q-1.53,1.07-3.15,2a7.2,7.2,0,0,1-3.92.47,2,2,0,0,0,.76,1.14q1.75,1.81,3.59,3.56t3.7,3.44A2,2,0,0,0,249.65,532.29Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M251.74,531.92a14.32,14.32,0,0,0,4.81-2.87,24.33,24.33,0,0,0,7.08-13.78,20.47,20.47,0,0,0-.91-9.09l-1.89-4.34-2.66-4a24.15,24.15,0,0,0-7.34-5.54l-4.41-1.67a13.36,13.36,0,0,0-7-.4,24.07,24.07,0,0,0-9.06,2.93,8.55,8.55,0,0,0-3.71,2.8,12.34,12.34,0,0,0-3,3.56,21.68,21.68,0,0,0-3.1,6.24,45.12,45.12,0,0,0-.68,4.62,22,22,0,0,0,1.55,7.2,12.5,12.5,0,0,0,2,4.26,8.1,8.1,0,0,0,2.88,3.59,23.45,23.45,0,0,0,7.64,4.8c3.42,1.33,1.62.78,6.59,1.44.53.07.57-.75.07-.83-5.92-1-11-3.09-14.9-7.63a19.23,19.23,0,0,1-2.77-6q-2.77-6.79,1.29-14.13a18.56,18.56,0,0,1,4-4.71,10.18,10.18,0,0,1,5.39-3.27,20.3,20.3,0,0,1,6.5-1.49,9.37,9.37,0,0,1,5.9.5c3.69,1.13,7.65,2.89,10.21,5.9l2.42,3.41a23.48,23.48,0,0,1,2.93,7.76,20.3,20.3,0,0,1-1.88,9.55A21.35,21.35,0,0,1,251.06,531C250.46,531.33,251.31,532.1,251.74,531.92Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M250.88,537.11l2.59-.67a11,11,0,0,0,3.93-2.79,19.94,19.94,0,0,0,5.14-8.3,32.78,32.78,0,0,0,1.06-4.43,19.1,19.1,0,0,0-.49-6.95c-.87-3.71-2.5-8.09-5.17-10.89a27.2,27.2,0,0,0-7.63-5.57,19.59,19.59,0,0,0-13.93-1.44l-4.4,1.56-4,2.3a13.44,13.44,0,0,0-1.72,1.74,23.51,23.51,0,0,0-5.74,9.72,19.72,19.72,0,0,0,1.57,13.9l2.37,4.06,3.24,3.16a17.35,17.35,0,0,0,6,3.35,14.51,14.51,0,0,0,6.72,1.51c.55.07.73-2.16.11-2.26l-4-.64a18.15,18.15,0,0,1-5.63-2.64,12,12,0,0,1-3.28-2.46,7.33,7.33,0,0,1-2.67-3.21,19.65,19.65,0,0,1-2.79-5.95l3.42-16.33,6.81-5.53q11.35-4.77,19.6,1.19a12.14,12.14,0,0,1,3.49,2.68,7.93,7.93,0,0,1,2.79,3.43,19.79,19.79,0,0,1,2.86,6.2l-.64,12.1a21.73,21.73,0,0,1-4,6.29c-2.06,2.29-4.76,3.35-7.6,4.2C248.53,534.53,250.06,537.32,250.88,537.11Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M249,532.79a9,9,0,0,0,4.93-1.85,12.49,12.49,0,0,0,4.17-5,19.21,19.21,0,0,0,2.28-10.81A20.1,20.1,0,0,0,254.12,503c-3.34-3-7.5-5.42-12.11-5.51a19.89,19.89,0,0,0-10.84,3.42,21.2,21.2,0,0,0-4.94,5.65,15.43,15.43,0,0,0-2.38,7.13,19.18,19.18,0,0,0,3.25,10.92,21.6,21.6,0,0,0,5.81,5,15.15,15.15,0,0,0,7.27,2.3c.27,0-.21-4.54-.27-5-.18-1.5-.37-3-.59-4.5a17.28,17.28,0,0,0-.58-3.1,1.42,1.42,0,0,0-1.59-1.27,2.64,2.64,0,0,1-1.16-1.85,4.19,4.19,0,0,0-.81-1.78,3.48,3.48,0,0,1,.79-2.88l-1.22.53a11.18,11.18,0,0,0,1.8-.6,2.24,2.24,0,0,1,1.37-2,3.55,3.55,0,0,0,2.46-1.06,3,3,0,0,1,2.74.61l-.2-.29c.21.81.8,1.14,1.78,1a3.53,3.53,0,0,1,1.79,2.48l1,1.28a4,4,0,0,1-.06,3.36,1.74,1.74,0,0,0-.51,1.21,2.88,2.88,0,0,1-1.12,2c-1,.58-1.93,1.15-2.9,1.71a6.93,6.93,0,0,1-3.77.37,2.48,2.48,0,0,0,.81,1.33q1.88,2.19,3.84,4.3t4,4.2A2.54,2.54,0,0,0,249,532.79Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M248.81,531a9.14,9.14,0,0,0,4.94-1.76,12.9,12.9,0,0,0,4.27-4.77,19.17,19.17,0,0,0,2.61-10.73,19.87,19.87,0,0,0-6-12.49c-3.39-3.1-7.71-5.73-12.46-5.78a20.42,20.42,0,0,0-11.12,3.72,22.39,22.39,0,0,0-4.86,6,15,15,0,0,0-2.14,7.17A19.24,19.24,0,0,0,227.67,523a20.9,20.9,0,0,0,5.72,4.74,15.21,15.21,0,0,0,7,2.15c.3,0-.15-4-.2-4.42a37.69,37.69,0,0,0-1.05-6.7c-.07-.24-.16-.48-.24-.71a2.26,2.26,0,0,1-2.07-1.23,3.67,3.67,0,0,0-1.15-2.38,2.93,2.93,0,0,1,.5-2.73l-.31.19c.78-.14,1.12-.66,1-1.57a2.48,2.48,0,0,1,1.74-1.26,4,4,0,0,0,1.83-1,3.48,3.48,0,0,1,3,.48l-.21-.33a1.67,1.67,0,0,0,1.92,1.21,4.09,4.09,0,0,1,1.86,2.69c.82,1.41,1.91,3.17.66,4.83a1.43,1.43,0,0,0-.69,1.22,3,3,0,0,1-1.2,2l-3,1.7a7,7,0,0,1-3.83.35,2.08,2.08,0,0,0,.83,1.15q1.88,1.82,3.83,3.56t3.93,3.43A2.1,2.1,0,0,0,248.81,531Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M249.6,534.62a8.76,8.76,0,0,0,5-1.91,16.84,16.84,0,0,0,4.41-5.09,20.34,20.34,0,0,0,2-15.54,26.06,26.06,0,0,0-3.66-8.26,24.75,24.75,0,0,0-6.77-5.62A18,18,0,0,0,238,496a23.12,23.12,0,0,0-8.38,3.23,20.55,20.55,0,0,0-7.54,10.53,20.1,20.1,0,0,0,2.82,16.61,21.92,21.92,0,0,0,6.41,5.56,16.31,16.31,0,0,0,7.82,2.36c.29,0-.53-2.36-.61-2.57-.28-.75-.57-1.49-.88-2.22a4.91,4.91,0,0,0-.77-1.47,30.89,30.89,0,0,1-5.66-3.54,13.13,13.13,0,0,1-3.69-5.15,12.73,12.73,0,0,1-1.24-4.94l.91-5.61,22.41-6.89a13.3,13.3,0,0,1,3.68,3.72,16.13,16.13,0,0,1,.95,16.94,13.76,13.76,0,0,1-3.67,3.82,9.67,9.67,0,0,1-6.12,2c-.28,0,2.35,3.45,2.27,3.35C247,532.1,248.8,534.79,249.6,534.62Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M251.82,532.78a12.71,12.71,0,0,0,3.76-1.5,10.3,10.3,0,0,0,3.67-3.16,24.18,24.18,0,0,0,3.85-6.91l1-4.21a13.92,13.92,0,0,0-.33-6.73c-.78-3.68-2.32-7.88-4.9-10.71a26.37,26.37,0,0,0-7.3-5.64l-4.35-1.71a12.61,12.61,0,0,0-7.06-.44c-3.72.46-8.22,1.64-11.17,4.09a23.77,23.77,0,0,0-6.73,9.35,17.62,17.62,0,0,0-.79,11.42,32.37,32.37,0,0,0,1.61,4.55,28.93,28.93,0,0,0,2.42,4.09,29.51,29.51,0,0,0,3.33,3.09,17.25,17.25,0,0,0,5.83,3.2,14.6,14.6,0,0,0,6.68,1.48c.54.07.52-1.27,0-1.35l-4.08-.62q-9.39-2.05-8.17-14.14-8.4-8.1-3.09-14.27a9,9,0,0,1,4.58-4.09,13.08,13.08,0,0,1,7.69-3.66l4.55-.55,3.72.93A17,17,0,0,1,251.9,498a11.38,11.38,0,0,1,3.1,2.47,7.84,7.84,0,0,1,2.54,3.16,19.19,19.19,0,0,1,2.68,5.74l.89,4.17a20.17,20.17,0,0,1-1.35,7.2A16.32,16.32,0,0,1,250,530.87C249.23,531.13,251.32,532.92,251.82,532.78Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M249.8,534.64a8.76,8.76,0,0,0,4.94-1.9,14.34,14.34,0,0,0,4.25-5.12,19.93,19.93,0,0,0,2.31-11,21.07,21.07,0,0,0-5.94-13c-3.38-3.26-7.44-5.72-12.23-6.05a17.8,17.8,0,0,0-17.49,8.9,15.54,15.54,0,0,0-2.58,7.5c-.15,4.09.73,8.74,3.08,12.15a21.52,21.52,0,0,0,6.14,5.34A16.72,16.72,0,0,0,240,534a25.67,25.67,0,0,0-.81-4c-.34-1.18-.69-2.35-1.07-3.51a11,11,0,0,0-.9-2.38c-1.54-1.49-3.43-2.23-4.82-3.9a11,11,0,0,1-2.22-7.16,12.51,12.51,0,0,1,4.64-7.66,6.27,6.27,0,0,1,6.93-.79,7.72,7.72,0,0,1,6.55,1.74A12.56,12.56,0,0,1,252,512.9l.24,2.61a4.21,4.21,0,0,1-1.1,3.6l-.71,1.9a5.32,5.32,0,0,1-2.1,2.18,25,25,0,0,1-2.49,1.59,7.22,7.22,0,0,1-3.92.53,1.79,1.79,0,0,0,.59,1.17c1,1.27,2,2.51,3,3.74s2.08,2.44,3.16,3.63A1.89,1.89,0,0,0,249.8,534.64Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M248.7,531.81a9,9,0,0,0,4.92-1.8,12.57,12.57,0,0,0,4.21-4.85,19,19,0,0,0,2.43-10.71,19.9,19.9,0,0,0-6-12.31c-3.35-3-7.58-5.58-12.25-5.66A20.14,20.14,0,0,0,231,500a21.57,21.57,0,0,0-4.91,5.81,15.19,15.19,0,0,0-2.28,7.16,19.23,19.23,0,0,0,3.44,10.78,21.38,21.38,0,0,0,5.79,4.84,15.13,15.13,0,0,0,7.14,2.2c.3.06-.13-4.28-.18-4.72-.14-1.42-.31-2.84-.49-4.25a16.21,16.21,0,0,0-.52-2.93q-.14-1.21-1.5-1.11a2.38,2.38,0,0,1-1.13-1.74,3.34,3.34,0,0,0-.87-1.71,3.15,3.15,0,0,1,.64-2.79l-.31.21c.83-.08,1.2-.57,1.13-1.49a2.38,2.38,0,0,1,1.75-1.13,3.22,3.22,0,0,0,1.76-.93,3.23,3.23,0,0,1,2.87.57l-.21-.3a1.47,1.47,0,0,0,1.82,1.1,3.77,3.77,0,0,1,1.79,2.58l.9,1.29a4.08,4.08,0,0,1-.15,3.38c-.75,1.07-.65,2.2-1.74,3.15-1,.57-1.95,1.13-2.93,1.69A6.91,6.91,0,0,1,239,522a2.2,2.2,0,0,0,.81,1.23c1.23,1.34,2.49,2.64,3.77,3.92s2.57,2.57,3.89,3.81A2.23,2.23,0,0,0,248.7,531.81Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M250.21,533.8a8.85,8.85,0,0,0,5-1.86,14.45,14.45,0,0,0,4.3-5.05,19.89,19.89,0,0,0,2.45-11,25.1,25.1,0,0,0-2.33-8.14,11.72,11.72,0,0,0-3.66-5c-3.44-3.29-7.59-5.78-12.46-6.07a21.59,21.59,0,0,0-12.1,3.08,22,22,0,0,0-5.49,6,15.35,15.35,0,0,0-2.5,7.43,20.81,20.81,0,0,0,3.08,12.13,21.84,21.84,0,0,0,6.12,5.41,15.3,15.3,0,0,0,7.61,2.42,19.27,19.27,0,0,0-.88-3.43c-.36-1-.73-2-1.12-3a8.77,8.77,0,0,0-.94-2,11.57,11.57,0,0,0-3.38-2.28,7.92,7.92,0,0,1-2.5-3,8.77,8.77,0,0,1-1.47-3.63,6.23,6.23,0,0,1,.57-4.91c1.18-3.29,3.06-6.11,6.56-7.33l2.53-.68a9.26,9.26,0,0,1,7,1.52,11.27,11.27,0,0,1,5.27,5.7,10.44,10.44,0,0,1,1.21,5.14l-.43,2.93a6.12,6.12,0,0,0-1.41,2.9l-1.29,1.67a29.16,29.16,0,0,1-3.41,2.31,7.28,7.28,0,0,1-4,.58,1.58,1.58,0,0,0,.6,1.06q1.45,1.68,3,3.28c1,1.08,2,2.14,3.11,3.17A1.58,1.58,0,0,0,250.21,533.8Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M251.49,537.66a7.49,7.49,0,0,0,3.94-1.46A11.21,11.21,0,0,0,259.3,533a23.43,23.43,0,0,0,4.86-11.42c.75-5.73-1.17-11.33-4.31-16.07-1.77-2.68-1.73-2.85-5-5.21a26.57,26.57,0,0,0-4.14-2.46,19.2,19.2,0,0,0-13.67-1.43,26.19,26.19,0,0,0-8.79,4.07,16.3,16.3,0,0,0-3.05,3.26,24.8,24.8,0,0,0-4.19,8.08,20.25,20.25,0,0,0,1.5,13.79,16.76,16.76,0,0,0,5.71,7.4l3.84,2.47a19.86,19.86,0,0,0,8.89,2.35c.54.08.64-2,0-2.13a20.36,20.36,0,0,1-8.06-2.09l-3.62-2.14a13.69,13.69,0,0,1-5.47-6.5q30.09-36.19,37.06-14.87a17.85,17.85,0,0,1,.15,14.34,17.42,17.42,0,0,1-2.87,4.87,10.46,10.46,0,0,1-3.54,3.46A13.28,13.28,0,0,1,249.4,535C249.12,535.05,250.67,537.87,251.49,537.66Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#ed1e79', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M206.39,511.69a6.88,6.88,0,1,0-6.87-6.87A6.89,6.89,0,0,0,206.39,511.69Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f2df83', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M231.41,541.8a6.88,6.88,0,1,0-6.87-6.87A6.89,6.89,0,0,0,231.41,541.8Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f2df83', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M211.06,541.8a5.6,5.6,0,1,0-5.61-5.6A5.61,5.61,0,0,0,211.06,541.8Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f2df83', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M186,555.52a7.42,7.42,0,1,0-7.41-7.42A7.43,7.43,0,0,0,186,555.52Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f1e29b', fillRule: 'evenodd' }}
              />
              <g>
                <motion.path
                  variants={icon}
                  initial='hidden'
                  animate={flowerOpaque1 ? 'visible' : 'hidden'}
                  transition={{
                    default: { duration: 4, ease: 'easeInOut' },
                    fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                  }}
                  d='M189.71,542.41a18.52,18.52,0,1,0-18.52-18.51A18.55,18.55,0,0,0,189.71,542.41Z'
                  transform='translate(-131.82 -489.96)'
                  style={{ fill: '#b4a6ed', fillRule: 'evenodd' }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M197.22,548.66a11.23,11.23,0,0,0,4.59-.87,9.12,9.12,0,0,0,3.06-1.35,23.34,23.34,0,0,0,6.87-5.83,28.4,28.4,0,0,0,6.09-19.1,29.24,29.24,0,0,0-9.28-19.31l-3.36-2.81a24.86,24.86,0,0,0-5.81-2.79c-3.28-1.23-7.08-2.3-10.62-2a28.7,28.7,0,0,0-19.15,9.33,26.55,26.55,0,0,0-5.1,27.75,25.15,25.15,0,0,0,5.61,8.74,25.86,25.86,0,0,0,8.18,6.09l3.84,1.47a26.8,26.8,0,0,0,8.05,1.25c.33,0-1.53-5.32-1.48-5.19-.47-1.33-1-2.66-1.51-4a1.45,1.45,0,0,0-.67-1c-1.45-.92-3-1.2-4.48-2.08a18.82,18.82,0,0,1-4.47-3.94,17,17,0,0,1-2.67-5.64,9.23,9.23,0,0,1-.71-3.86l.3-2.82.61-1.25a7.62,7.62,0,0,1,1.25-3.37,17.66,17.66,0,0,1,4-4.68,18.44,18.44,0,0,1,5.77-2.82c1.87-.64,5-1.3,6.89-.49l1.37.74,2.34.7a8.55,8.55,0,0,1,3.11,2.11q1.41,1.26,2.76,2.58a17,17,0,0,1,3.64,11.39,4.12,4.12,0,0,1-.87,3l-.68,2.44A15.55,15.55,0,0,1,201,536.3a13.85,13.85,0,0,1-5.13,3.37,18.79,18.79,0,0,1-7.69,1.32,1.72,1.72,0,0,0,.75,1q1.72,1.59,3.53,3.09t3.62,3A1.71,1.71,0,0,0,197.22,548.66Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M198.4,550.71a11.26,11.26,0,0,0,4.63-.91,9.37,9.37,0,0,0,3.07-1.39,23.28,23.28,0,0,0,6.86-6.06A28.48,28.48,0,0,0,218.67,523,29.38,29.38,0,0,0,209,504a28,28,0,0,0-9.09-5.39,25.7,25.7,0,0,0-10.34-1.92,28.82,28.82,0,0,0-19.12,8.89,28.3,28.3,0,0,0-7.39,17,27.34,27.34,0,0,0,1.6,10.63,25.08,25.08,0,0,0,5.55,9,26.23,26.23,0,0,0,8.34,6.37l4,1.55a27.09,27.09,0,0,0,8.38,1.32c.28,0-1.85-5.55-1.8-5.41-.55-1.39-1.14-2.77-1.76-4.13a1.67,1.67,0,0,0-.73-1c-1.72-.89-3.55-1.31-5.22-2.26a19.2,19.2,0,0,1-5.06-4.32,18,18,0,0,1-3-6.21,11.21,11.21,0,0,1-.77-4.26l.26-3.11.8-1.53a9,9,0,0,1,1.44-3.62,18.74,18.74,0,0,1,4.3-5,19.94,19.94,0,0,1,6.18-3c2-.66,5.31-1.34,7.34-.54l1.5.73,2.5.75a18,18,0,0,1,6.3,4.87,18.46,18.46,0,0,1,4.11,12.05,4.85,4.85,0,0,1-.72,3.19l-.68,2.62a16.39,16.39,0,0,1-3.7,5.53,14.63,14.63,0,0,1-5.24,3.65,18.48,18.48,0,0,1-7.76,1.41,2,2,0,0,0,.76,1.14q1.75,1.82,3.59,3.56t3.7,3.44A2,2,0,0,0,198.4,550.71Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M200.34,550.39a16.11,16.11,0,0,0,6-2.3l2.85-2.1a31.45,31.45,0,0,0,5.72-5.75,31.12,31.12,0,0,0,6-16.89c1.16-19.36-18.32-36.7-37.49-32a30.83,30.83,0,0,0-13,6.2,30.29,30.29,0,0,0-8.68,11.16c-1.81,4.37-3.21,9.2-2.73,14a34.15,34.15,0,0,0,2.41,9.81,31.45,31.45,0,0,0,8.26,11.46,19.51,19.51,0,0,0,5.83,3.92,25.34,25.34,0,0,0,6.52,2.58,28.93,28.93,0,0,0,9.26,1.05c.66,0,.59-1.07.15-1.09a27.48,27.48,0,0,1-9.06-1.09q-13-3.48-19.27-17.3a25.11,25.11,0,0,1-.56-17.7,33.55,33.55,0,0,1,3.9-7.81,35.81,35.81,0,0,1,6.47-6,30.17,30.17,0,0,1,14.95-5.29c10.11-1.06,20.36,4.63,25.9,12.95a26.4,26.4,0,0,1,4.11,20,27,27,0,0,1-10.16,16.56,16.86,16.86,0,0,1-7.86,4.7C199.22,549.63,200,550.48,200.34,550.39Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M199.43,555.57a16.63,16.63,0,0,0,6.58-1.85,23.46,23.46,0,0,0,6.16-4.59A30.24,30.24,0,0,0,219,538.25a31.3,31.3,0,0,0-.93-23.5c-5.79-13.33-21-21.54-35.3-18A31.2,31.2,0,0,0,160,518.87a29.86,29.86,0,0,0,2.26,21.33,30.35,30.35,0,0,0,10.87,12.26,32.72,32.72,0,0,0,8.64,3.7,30.38,30.38,0,0,0,9.49,1c.51,0,.35-2.84-.34-2.88a33.35,33.35,0,0,1-8.55-1.14l-4.11-1.41a27.57,27.57,0,0,1-10.34-7.61,29.19,29.19,0,0,1-6.16-11.81l30.53-35.44,2.29.45a25,25,0,0,1,6.65,1.95l17,20.4a22.69,22.69,0,0,1,.76,9c-.53,4.14-1.62,8.7-3.92,12.23a30.47,30.47,0,0,1-6.65,7.22,17,17,0,0,1-4.52,2.89,20.11,20.11,0,0,1-6.32,1.81C197.34,552.91,198.82,555.67,199.43,555.57Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M197.7,551.21a11.16,11.16,0,0,0,4.61-.94,9.12,9.12,0,0,0,3.05-1.41,23.43,23.43,0,0,0,6.74-6.08,28.29,28.29,0,0,0,5.46-19.13A29.19,29.19,0,0,0,208.08,505a27.85,27.85,0,0,0-8.87-5.33,25.66,25.66,0,0,0-10.15-2,27.81,27.81,0,0,0-23.68,15.39,27.68,27.68,0,0,0-2.75,10.16,27,27,0,0,0,1.57,10.6,24.57,24.57,0,0,0,5.55,9,25.55,25.55,0,0,0,8.37,6.34l4,1.51a25.11,25.11,0,0,0,8.39,1.24c.31,0-1.72-6.82-1.67-6.66q-.78-2.6-1.68-5.15a2.18,2.18,0,0,0-.71-1.29c-1.46-.87-3-1.18-4.5-2a18.23,18.23,0,0,1-4.47-4,16.48,16.48,0,0,1-2.57-5.67,8.85,8.85,0,0,1-.6-3.82l.37-2.79.86-1.3a7.08,7.08,0,0,1,1.34-3.16,17.73,17.73,0,0,1,4-4.35,18.28,18.28,0,0,1,5.61-2.45c1.75-.55,4.78-1.05,6.46-.17l1.2.77,2.18.64a7.55,7.55,0,0,1,2.85,2c.84.83,1.7,1.65,2.58,2.43a15.36,15.36,0,0,1,2.27,4.36,13.57,13.57,0,0,1,1.14,6.45,4.4,4.4,0,0,1-.71,2.75l-.61,2.39a15,15,0,0,1-3.5,5,13.07,13.07,0,0,1-5,3.27,18.28,18.28,0,0,1-7.55,1.26,2.48,2.48,0,0,0,.81,1.33q1.89,2.19,3.84,4.3c1.3,1.43,2.61,2.83,4,4.2A2.54,2.54,0,0,0,197.7,551.21Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M197.56,549.41a11.34,11.34,0,0,0,4.61-.89,9.56,9.56,0,0,0,3.06-1.36,23.35,23.35,0,0,0,6.84-5.91,28.27,28.27,0,0,0,5.91-19.1,29.07,29.07,0,0,0-9.39-19l-3.32-2.75a24.84,24.84,0,0,0-5.76-2.71c-3.23-1.2-7-2.24-10.45-1.95a28.73,28.73,0,0,0-19,9.1,27.84,27.84,0,0,0-4.61,6.95,26,26,0,0,0-.73,20.55,24.93,24.93,0,0,0,5.55,8.78,25.71,25.71,0,0,0,8.19,6.15l3.86,1.49a26.47,26.47,0,0,0,8.14,1.27c.3,0-1.7-5.92-1.65-5.78q-.78-2.24-1.66-4.44a1.8,1.8,0,0,0-.7-1.09,4.64,4.64,0,0,0-2.28-1.13,4.51,4.51,0,0,1-2.06-.94,18.51,18.51,0,0,1-4.31-3.89,16.67,16.67,0,0,1-2.52-5.52,8.4,8.4,0,0,1-.63-3.72l.35-2.73.68-1.19a7.19,7.19,0,0,1,1.22-3.21,17.6,17.6,0,0,1,3.88-4.45,18.27,18.27,0,0,1,5.59-2.63c1.79-.6,4.83-1.19,6.6-.36l1.25.73,2.25.66a7.85,7.85,0,0,1,3,2.05l2.64,2.5a16.27,16.27,0,0,1,3.43,11,4,4,0,0,1-.85,2.86l-.65,2.38a15,15,0,0,1-3.6,5,13.19,13.19,0,0,1-5,3.25,18.82,18.82,0,0,1-7.63,1.25,2,2,0,0,0,.82,1.15q1.88,1.81,3.83,3.56t3.93,3.43A2.1,2.1,0,0,0,197.56,549.41Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M198.35,553a10.93,10.93,0,0,0,4.62-1,9.12,9.12,0,0,0,3-1.47,26.83,26.83,0,0,0,7-6.24c4.42-5.4,6.34-12.87,6.06-19.74a26.84,26.84,0,0,0-1.43-7.06,26.2,26.2,0,0,0-2.61-6.57,37.55,37.55,0,0,0-5.75-7,28.22,28.22,0,0,0-11.78-6.63,27.59,27.59,0,0,0-8.55-1.21,33.41,33.41,0,0,0-9.58,2L175,500a24.27,24.27,0,0,0-5.31,4.13,27.2,27.2,0,0,0-6.62,9A31.17,31.17,0,0,0,160.41,524a28.27,28.27,0,0,0,1.79,11.41,29,29,0,0,0,6,9.62,28.19,28.19,0,0,0,8.94,6.61l4.21,1.58a25.59,25.59,0,0,0,8.69,1.15c.39,0-1.27-3.68-1.22-3.57-.31-.68-1-3-1.9-3.25-1.87-.6-3.8-1.07-5.64-1.72a22.49,22.49,0,0,1-7.42-4.7,24,24,0,0,1-7.66-14.61l.27-6.53.87-3.57a23.48,23.48,0,0,1,4.49-7.84c5.22-6.08,14.09-10,22.28-8.09a26.12,26.12,0,0,1,13.29,7.4,22.23,22.23,0,0,1,4.93,8.39,16.27,16.27,0,0,1,1,4.12,11.17,11.17,0,0,1,.14,5.51,16.52,16.52,0,0,1-1.25,6.84L210.73,536a24,24,0,0,1-4.09,5.32,21,21,0,0,1-6.51,4.2,18.63,18.63,0,0,1-6.91,1.33c-.28,0,2.35,3.45,2.27,3.35C195.83,550.58,197.49,553.16,198.35,553Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M200.57,551.2a20.32,20.32,0,0,0,6.26-1.92,24.43,24.43,0,0,0,4.4-3.14,28.89,28.89,0,0,0,9.52-16.3,31.15,31.15,0,0,0,.16-11.55,35.36,35.36,0,0,0-4.14-11.23,38.27,38.27,0,0,0-9.75-10,34.23,34.23,0,0,0-10.93-4.28A29.9,29.9,0,0,0,173,497.69a35,35,0,0,0-8.3,8.35,25.76,25.76,0,0,0-3,6.43,25.45,25.45,0,0,0-1.75,6.94,30.44,30.44,0,0,0,1.45,12,31,31,0,0,0,5.68,10.47,29.26,29.26,0,0,0,11.2,8.34l4.48,1.54a29.4,29.4,0,0,0,9.47,1c.5,0,.13-1.73-.24-1.74a43.55,43.55,0,0,1-8.88-1.07,26.27,26.27,0,0,1-9.8-4.74,33,33,0,0,1-6.1-6.22,28,28,0,0,1-4.57-10.3,23.64,23.64,0,0,1-.55-8.9,31.14,31.14,0,0,1,3.66-10.32,28.37,28.37,0,0,1,15.14-12.14l4.59-1.12,4.81-.53a30.05,30.05,0,0,1,7.9,1.54,29,29,0,0,1,19,20.8l.57,8.13-.64,4.06a25.83,25.83,0,0,1-3.89,8.93,13.3,13.3,0,0,1-6.36,5.52,11.42,11.42,0,0,1-8.14,4.58C198,549.46,200,551.3,200.57,551.2Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M198.55,553.06a10.87,10.87,0,0,0,4.61-1,9.07,9.07,0,0,0,3-1.45A23.93,23.93,0,0,0,213,544.4a29.23,29.23,0,0,0,5.55-19.47,29.83,29.83,0,0,0-9.53-19.53,29.26,29.26,0,0,0-9.13-5.58,25.64,25.64,0,0,0-10.29-2c-7.47.37-14.81,3.18-20,8.74a29.35,29.35,0,0,0-7.74,17.63,28.51,28.51,0,0,0,1.56,11.09,25.67,25.67,0,0,0,5.73,9.48,27.21,27.21,0,0,0,8.69,6.59l4.11,1.57a26.89,26.89,0,0,0,8.63,1.25c.3,0-1.83-5.55-1.77-5.41-.55-1.39-1.13-2.77-1.75-4.13a1.6,1.6,0,0,0-.72-1c-1.95-.91-4-1.38-5.92-2.39a20.1,20.1,0,0,1-5.88-4.64,21.1,21.1,0,0,1-3.2-5.28l-1.09-3.11a9.71,9.71,0,0,1-.16-6.14c.45-3,1-5.91,2.7-8.46a24,24,0,0,1,4-4.73A19.36,19.36,0,0,1,184,505a17.39,17.39,0,0,1,10.82-.09A20.52,20.52,0,0,1,206.07,513a20.28,20.28,0,0,1,3.21,7.47l.45,3.46-.14,3.65a16.56,16.56,0,0,0-1.1,3.15,8.25,8.25,0,0,1-1.1,2.78,19.35,19.35,0,0,1-4.3,5.73,19.68,19.68,0,0,1-4.63,3,17.25,17.25,0,0,1-7.76,1.51,1.79,1.79,0,0,0,.59,1.17c1,1.27,2,2.51,3,3.74s2.08,2.44,3.17,3.63A1.84,1.84,0,0,0,198.55,553.06Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M197.45,550.23a11.15,11.15,0,0,0,4.6-.91,9.29,9.29,0,0,0,3-1.38,23.34,23.34,0,0,0,6.78-6,28.21,28.21,0,0,0,5.69-19.05,29.06,29.06,0,0,0-9.38-18.81l-3.28-2.7a24.62,24.62,0,0,0-5.67-2.71c-3.19-1.2-6.87-2.23-10.31-2a28.57,28.57,0,0,0-19,8.81,28.19,28.19,0,0,0-4.71,6.88,27.75,27.75,0,0,0-2.61,10.14,27,27,0,0,0,1.68,10.53,24.58,24.58,0,0,0,5.58,8.89,25.55,25.55,0,0,0,8.29,6.22l3.91,1.49a26,26,0,0,0,8.26,1.24c.32,0-1.63-6.39-1.58-6.23-.51-1.62-1-3.22-1.61-4.82a2,2,0,0,0-.7-1.19c-1.42-.88-3-1.17-4.39-2a18,18,0,0,1-4.38-3.92,16.34,16.34,0,0,1-2.54-5.58,8.59,8.59,0,0,1-.62-3.76l.36-2.76.77-1.23a7.07,7.07,0,0,1,1.28-3.18,17.58,17.58,0,0,1,3.94-4.39,18.25,18.25,0,0,1,5.59-2.53c1.76-.57,4.81-1.12,6.52-.25l1.23.76,2.2.65a7.69,7.69,0,0,1,2.89,2c.85.84,1.72,1.66,2.59,2.47a16.11,16.11,0,0,1,3.37,10.87,4.13,4.13,0,0,1-.79,2.78l-.63,2.37a14.74,14.74,0,0,1-3.54,5,13,13,0,0,1-5,3.25,18.56,18.56,0,0,1-7.58,1.25,2.2,2.2,0,0,0,.81,1.23c1.23,1.34,2.49,2.64,3.77,3.92s2.57,2.57,3.89,3.81A2.27,2.27,0,0,0,197.45,550.23Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M199,552.22a10.89,10.89,0,0,0,4.61-1,9.12,9.12,0,0,0,3.06-1.43,24.11,24.11,0,0,0,6.89-6.19,29.16,29.16,0,0,0,5.7-19.54,29.89,29.89,0,0,0-9.67-19.65,29.38,29.38,0,0,0-9.24-5.59,25.76,25.76,0,0,0-10.39-2c-7.5.42-14.87,3.26-20,8.87a28.72,28.72,0,0,0-7.68,17.6,30.3,30.3,0,0,0,1.6,11.09,24.9,24.9,0,0,0,5.76,9.41,27.8,27.8,0,0,0,8.68,6.54l4.1,1.59a27.27,27.27,0,0,0,8.6,1.3c.29,0-1.86-4.81-1.8-4.68-.43-.91-1.35-3.86-2.49-4.36-2-.9-4.21-1.4-6.21-2.43a21.89,21.89,0,0,1-6.14-4.74,20,20,0,0,1-3.93-7,17.3,17.3,0,0,1-.39-10.87A20.7,20.7,0,0,1,185.58,504c2.62-.57,5.95-1,8.42,0s4.67,1.44,6.79,2.95a27.77,27.77,0,0,1,4.74,4.26,20,20,0,0,1,3.77,7.24,16.88,16.88,0,0,1,1,5.18l-.06,3.71-.71,1.81a12.68,12.68,0,0,1-1.5,4.33,19.76,19.76,0,0,1-4.32,5.92,20,20,0,0,1-4.69,3.07,17.23,17.23,0,0,1-7.84,1.59,1.53,1.53,0,0,0,.59,1.06q1.45,1.68,3,3.28t3.11,3.17A1.58,1.58,0,0,0,199,552.22Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M200.05,556.11a16.53,16.53,0,0,0,6.6-1.83,22.4,22.4,0,0,0,6.16-4.6,29.6,29.6,0,0,0,6.79-10.93,31.29,31.29,0,0,0,.69-18.9,32.41,32.41,0,0,0-14-19,32,32,0,0,0-17.94-4.62c-14.65.91-27.5,13-28.78,27.75a30.82,30.82,0,0,0,4.34,18.85,33.48,33.48,0,0,0,9.82,10.08,32.83,32.83,0,0,0,8.64,3.74,28.8,28.8,0,0,0,9.51,1.05c.51,0,.3-2.72-.39-2.76l-4.28-.21a25.46,25.46,0,0,1-6.46-1.59,26.84,26.84,0,0,1-7.71-4,31.18,31.18,0,0,1-7.55-7.82l-2.1-4.09,49.51-29.51,3.39,10.69q4.76,6.24,3,12.87a16.24,16.24,0,0,1-1.08,4.23,26.55,26.55,0,0,1-4.88,9.08,30.92,30.92,0,0,1-5.71,5.25,23,23,0,0,1-9.24,3.44C197.5,553.52,199.53,556.2,200.05,556.11Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#b4a6ed', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <g>
                <motion.path
                  d='M200.05,556.11a16.53,16.53,0,0,0,6.6-1.83,22.4,22.4,0,0,0,6.16-4.6,29.6,29.6,0,0,0,6.79-10.93,31.29,31.29,0,0,0,.69-18.9,32.41,32.41,0,0,0-14-19,32,32,0,0,0-17.94-4.62c-14.65.91-27.5,13-28.78,27.75a30.82,30.82,0,0,0,4.34,18.85,33.48,33.48,0,0,0,9.82,10.08,32.83,32.83,0,0,0,8.64,3.74,28.8,28.8,0,0,0,9.51,1.05c.51,0,.3-2.72-.39-2.76l-4.28-.21a25.46,25.46,0,0,1-6.46-1.59,26.84,26.84,0,0,1-7.71-4,31.18,31.18,0,0,1-7.55-7.82l-2.1-4.09,49.51-29.51,3.39,10.69q4.76,6.24,3,12.87a16.24,16.24,0,0,1-1.08,4.23,26.55,26.55,0,0,1-4.88,9.08,30.92,30.92,0,0,1-5.71,5.25,23,23,0,0,1-9.24,3.44C197.5,553.52,199.53,556.2,200.05,556.11Z'
                  style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                />
                <g>
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M264.81,563.93a11.25,11.25,0,0,0,4.6-.87,9.12,9.12,0,0,0,3.06-1.35,23.6,23.6,0,0,0,6.87-5.83,28.52,28.52,0,0,0,6.09-19.1,29.25,29.25,0,0,0-9.29-19.32l-3.35-2.81a24.83,24.83,0,0,0-5.81-2.78c-3.28-1.23-7.08-2.3-10.62-2a28.73,28.73,0,0,0-19.16,9.33A26.51,26.51,0,0,0,232.11,547a25,25,0,0,0,5.61,8.74,25.74,25.74,0,0,0,8.18,6.09l3.84,1.47a26.79,26.79,0,0,0,8,1.25c.33,0-1.52-5.32-1.47-5.19-.47-1.33-1-2.66-1.52-4a1.4,1.4,0,0,0-.66-1c-1.45-.92-3-1.2-4.48-2.08a18.69,18.69,0,0,1-4.48-4,16.81,16.81,0,0,1-2.66-5.64,9,9,0,0,1-.71-3.85l.29-2.82.62-1.25a7.62,7.62,0,0,1,1.25-3.37,17.52,17.52,0,0,1,4-4.69,18.55,18.55,0,0,1,5.77-2.82c1.87-.63,5-1.29,6.89-.48l1.36.74,2.35.7A8.55,8.55,0,0,1,267.4,527q1.39,1.26,2.76,2.58A17,17,0,0,1,273.8,541a4.1,4.1,0,0,1-.88,3l-.67,2.44a15.55,15.55,0,0,1-3.66,5.14,13.85,13.85,0,0,1-5.13,3.37,18.84,18.84,0,0,1-7.7,1.32,1.73,1.73,0,0,0,.76,1c1.15,1.06,2.33,2.09,3.52,3.09s2.4,2,3.63,3A1.71,1.71,0,0,0,264.81,563.93Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M266,566a11.3,11.3,0,0,0,4.64-.91,9.37,9.37,0,0,0,3.07-1.39,23.53,23.53,0,0,0,6.86-6.06,28.54,28.54,0,0,0,5.71-19.34,29.44,29.44,0,0,0-9.65-19,28.15,28.15,0,0,0-9.1-5.4,25.78,25.78,0,0,0-10.33-1.91,28.78,28.78,0,0,0-19.12,8.88,28.3,28.3,0,0,0-7.39,17.05,27.26,27.26,0,0,0,1.59,10.62,25.19,25.19,0,0,0,5.55,9,26.26,26.26,0,0,0,8.35,6.38l4,1.55a27,27,0,0,0,8.38,1.32c.28,0-1.85-5.55-1.8-5.41-.55-1.39-1.14-2.77-1.77-4.13a1.65,1.65,0,0,0-.72-1c-1.72-.89-3.55-1.31-5.22-2.26a19.25,19.25,0,0,1-5.07-4.33,18,18,0,0,1-3-6.2,11,11,0,0,1-.77-4.26l.25-3.11.81-1.54a9,9,0,0,1,1.44-3.61,18.56,18.56,0,0,1,4.3-5,19.72,19.72,0,0,1,6.18-3c2-.66,5.31-1.34,7.34-.54l1.49.73,2.51.74a18,18,0,0,1,10.41,16.93,4.86,4.86,0,0,1-.72,3.19l-.68,2.62a16.52,16.52,0,0,1-3.71,5.53,14.59,14.59,0,0,1-5.23,3.65,18.48,18.48,0,0,1-7.76,1.41,1.91,1.91,0,0,0,.76,1.14q1.75,1.82,3.59,3.56t3.7,3.44A2,2,0,0,0,266,566Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M267.94,565.66a16.18,16.18,0,0,0,6-2.3l2.85-2.1a31.83,31.83,0,0,0,5.72-5.76,31.14,31.14,0,0,0,6-16.89c1.16-19.35-18.33-36.69-37.49-32a30.83,30.83,0,0,0-13,6.2c-3.63,3.12-6.82,6.67-8.68,11.16s-3.21,9.2-2.73,14a34.15,34.15,0,0,0,2.41,9.81,31.39,31.39,0,0,0,8.25,11.45,19.41,19.41,0,0,0,5.84,3.93,25.27,25.27,0,0,0,6.51,2.58,29,29,0,0,0,9.26,1c.66,0,.6-1.07.16-1.09a27.48,27.48,0,0,1-9.06-1.09q-13-3.48-19.27-17.3a25.14,25.14,0,0,1-.56-17.71,33.85,33.85,0,0,1,3.89-7.8,35.87,35.87,0,0,1,6.48-6,30.17,30.17,0,0,1,14.95-5.29c10.11-1.06,20.36,4.63,25.89,12.94a26.4,26.4,0,0,1,4.12,20A27,27,0,0,1,275.27,560a17,17,0,0,1-7.86,4.7C266.82,564.9,267.59,565.75,267.94,565.66Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M267,570.84a16.68,16.68,0,0,0,6.58-1.85,23.63,23.63,0,0,0,6.16-4.59,30,30,0,0,0,6.82-10.89,31.25,31.25,0,0,0-.92-23.5c-5.79-13.32-21-21.53-35.31-18a31.21,31.21,0,0,0-22.79,22.16,29.81,29.81,0,0,0,2.26,21.33,30.25,30.25,0,0,0,10.86,12.26,32.92,32.92,0,0,0,8.65,3.7,30.38,30.38,0,0,0,9.49,1c.51,0,.34-2.84-.35-2.88a33.33,33.33,0,0,1-8.54-1.14L245.82,567a27.62,27.62,0,0,1-10.33-7.61,29.06,29.06,0,0,1-6.16-11.81l30.53-35.44,2.29.45a25.13,25.13,0,0,1,6.65,2l17,20.4a22.68,22.68,0,0,1,.77,9c-.53,4.14-1.62,8.7-3.92,12.23a30.66,30.66,0,0,1-6.65,7.22,17.15,17.15,0,0,1-4.52,2.89,20.19,20.19,0,0,1-6.32,1.81C264.93,568.18,266.42,570.94,267,570.84Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M265.3,566.48a11.19,11.19,0,0,0,4.61-.94,9.07,9.07,0,0,0,3-1.41,23.3,23.3,0,0,0,6.74-6.08,28.25,28.25,0,0,0,5.47-19.14,29.24,29.24,0,0,0-9.48-18.65,27.82,27.82,0,0,0-8.87-5.32,25.35,25.35,0,0,0-10.15-2A27.75,27.75,0,0,0,233,528.37a27.69,27.69,0,0,0-2.75,10.15,26.83,26.83,0,0,0,1.57,10.61,24.57,24.57,0,0,0,5.55,9,25.83,25.83,0,0,0,8.37,6.34l4,1.51a25.21,25.21,0,0,0,8.4,1.24c.3,0-1.72-6.82-1.67-6.66-.53-1.73-1.08-3.45-1.68-5.15a2.24,2.24,0,0,0-.71-1.29c-1.47-.87-3.05-1.18-4.5-2a18,18,0,0,1-4.47-4,16.37,16.37,0,0,1-2.57-5.67,8.85,8.85,0,0,1-.6-3.82l.37-2.79.85-1.3a7.21,7.21,0,0,1,1.35-3.16,17.89,17.89,0,0,1,4-4.35,18.2,18.2,0,0,1,5.62-2.45c1.75-.55,4.78-1.05,6.46-.17l1.2.77,2.17.63a7.53,7.53,0,0,1,2.85,2c.84.83,1.71,1.64,2.59,2.43a15.48,15.48,0,0,1,2.27,4.36,13.81,13.81,0,0,1,1.14,6.45,4.35,4.35,0,0,1-.71,2.74l-.61,2.4a15,15,0,0,1-3.51,5,13,13,0,0,1-4.94,3.27,18.28,18.28,0,0,1-7.55,1.26,2.48,2.48,0,0,0,.81,1.33q1.88,2.19,3.84,4.3t4,4.2A2.5,2.5,0,0,0,265.3,566.48Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M265.16,564.68a11.26,11.26,0,0,0,4.6-.89,9.37,9.37,0,0,0,3.06-1.36,23.38,23.38,0,0,0,6.85-5.91,28.3,28.3,0,0,0,5.91-19.11,29.11,29.11,0,0,0-9.39-19l-3.32-2.75a24.84,24.84,0,0,0-5.76-2.71c-3.23-1.21-7-2.24-10.45-1.95a28.68,28.68,0,0,0-19,9.1,28.15,28.15,0,0,0-4.62,7,27.72,27.72,0,0,0-2.48,10.11,27.22,27.22,0,0,0,1.76,10.44,24.65,24.65,0,0,0,5.55,8.78,25.6,25.6,0,0,0,8.19,6.15l3.85,1.49a26.24,26.24,0,0,0,8.15,1.27c.3,0-1.7-5.92-1.65-5.78-.52-1.49-1.08-3-1.66-4.44a1.85,1.85,0,0,0-.7-1.09,4.63,4.63,0,0,0-2.29-1.13,4.52,4.52,0,0,1-2.05-.94A18.35,18.35,0,0,1,245.4,548a16.85,16.85,0,0,1-2.51-5.52,8.4,8.4,0,0,1-.63-3.72l.35-2.73.68-1.19a7,7,0,0,1,1.22-3.21,17.43,17.43,0,0,1,3.88-4.45,18.09,18.09,0,0,1,5.58-2.63c1.8-.61,4.84-1.19,6.6-.36l1.26.73,2.25.66a7.85,7.85,0,0,1,3,2.05l2.64,2.49a16.36,16.36,0,0,1,3.43,11,4,4,0,0,1-.85,2.85l-.65,2.38a15,15,0,0,1-3.6,5,13.27,13.27,0,0,1-5,3.25,18.87,18.87,0,0,1-7.64,1.25,2.08,2.08,0,0,0,.83,1.15q1.88,1.82,3.83,3.56c1.28,1.17,2.6,2.31,3.93,3.43A2.1,2.1,0,0,0,265.16,564.68Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M266,568.31a10.85,10.85,0,0,0,4.61-1,9.07,9.07,0,0,0,3.05-1.47,26.83,26.83,0,0,0,7-6.24c4.42-5.4,6.33-12.88,6.06-19.75a27.26,27.26,0,0,0-1.43-7,26.2,26.2,0,0,0-2.61-6.57,37.94,37.94,0,0,0-5.75-7,28.31,28.31,0,0,0-11.78-6.62,27.59,27.59,0,0,0-8.55-1.21,33.41,33.41,0,0,0-9.58,2l-4.38,1.92a24.27,24.27,0,0,0-5.31,4.13,27.16,27.16,0,0,0-6.62,9A31.51,31.51,0,0,0,228,539.25a28.52,28.52,0,0,0,1.8,11.42,29,29,0,0,0,6,9.62,28.12,28.12,0,0,0,8.94,6.61l4.21,1.58a25.34,25.34,0,0,0,8.69,1.15c.39,0-1.27-3.68-1.22-3.57-.31-.68-1-3-1.91-3.25-1.86-.6-3.79-1.07-5.63-1.72a22.49,22.49,0,0,1-7.42-4.7,24,24,0,0,1-7.66-14.61l.27-6.53.87-3.57a23.31,23.31,0,0,1,4.49-7.84c5.21-6.09,14.09-10,22.27-8.09a26,26,0,0,1,13.29,7.4,22.15,22.15,0,0,1,4.93,8.39,15.4,15.4,0,0,1,1,4.11,11.21,11.21,0,0,1,.15,5.52,16.52,16.52,0,0,1-1.25,6.84l-1.52,3.22a24.15,24.15,0,0,1-4.09,5.33,21,21,0,0,1-6.51,4.2,18.68,18.68,0,0,1-6.92,1.33c-.27,0,2.36,3.45,2.28,3.35C263.43,565.85,265.09,568.43,266,568.31Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M268.17,566.47a20.32,20.32,0,0,0,6.26-1.92,24.36,24.36,0,0,0,4.39-3.14,28.85,28.85,0,0,0,9.53-16.3,30.94,30.94,0,0,0,.15-11.55,35,35,0,0,0-4.13-11.23,38.27,38.27,0,0,0-9.75-10,34.23,34.23,0,0,0-10.93-4.28A29.89,29.89,0,0,0,240.61,513a34.82,34.82,0,0,0-8.3,8.35,37.9,37.9,0,0,0-4.74,13.37,30.26,30.26,0,0,0,1.45,12,31.16,31.16,0,0,0,5.67,10.47,29.29,29.29,0,0,0,11.21,8.34l4.48,1.54a29.63,29.63,0,0,0,9.46,1.05c.51,0,.14-1.74-.23-1.75a43.46,43.46,0,0,1-8.88-1.07,26.31,26.31,0,0,1-9.81-4.74,33.22,33.22,0,0,1-6.09-6.22,28,28,0,0,1-4.57-10.3,23.67,23.67,0,0,1-.55-8.91,31.29,31.29,0,0,1,3.65-10.31,28.35,28.35,0,0,1,15.14-12.14l4.6-1.12,4.81-.54a30.32,30.32,0,0,1,7.89,1.55,29,29,0,0,1,19,20.8l.57,8.13-.65,4a25.85,25.85,0,0,1-3.88,8.94A13.35,13.35,0,0,1,274.5,560a11.42,11.42,0,0,1-8.15,4.58C265.61,564.73,267.62,566.57,268.17,566.47Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M266.14,568.33a10.92,10.92,0,0,0,4.62-1,9.07,9.07,0,0,0,3-1.45,24.08,24.08,0,0,0,6.83-6.24,29.24,29.24,0,0,0,5.55-19.48,29.85,29.85,0,0,0-9.53-19.52,29.19,29.19,0,0,0-9.14-5.58,25.56,25.56,0,0,0-10.28-2c-7.47.37-14.82,3.18-20,8.74a29.4,29.4,0,0,0-7.73,17.63,28.51,28.51,0,0,0,1.56,11.09,25.54,25.54,0,0,0,5.73,9.48,27.1,27.1,0,0,0,8.69,6.59l4.11,1.57a26.89,26.89,0,0,0,8.63,1.25c.3,0-1.83-5.55-1.77-5.41-.55-1.39-1.14-2.76-1.75-4.13a1.6,1.6,0,0,0-.72-1c-1.95-.91-4-1.38-5.92-2.39a20.14,20.14,0,0,1-5.89-4.64,21.07,21.07,0,0,1-3.19-5.28l-1.1-3.11a9.78,9.78,0,0,1-.16-6.14c.46-3,1-5.91,2.7-8.47a24.66,24.66,0,0,1,4.05-4.72,19.36,19.36,0,0,1,7.12-3.82,17.39,17.39,0,0,1,10.82-.09,20.57,20.57,0,0,1,14.44,15.54l.45,3.47-.14,3.65a17.32,17.32,0,0,0-1.11,3.15A7.91,7.91,0,0,1,275,548.8a19.43,19.43,0,0,1-4.3,5.74,19.88,19.88,0,0,1-4.63,3A17.25,17.25,0,0,1,258.3,559a1.79,1.79,0,0,0,.59,1.17c1,1.27,2,2.51,3,3.74s2.08,2.44,3.16,3.63A1.9,1.9,0,0,0,266.14,568.33Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque1 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M265,565.5a11.12,11.12,0,0,0,4.6-.91,9.25,9.25,0,0,0,3.05-1.38,23.34,23.34,0,0,0,6.78-6,28.21,28.21,0,0,0,5.69-19,29.08,29.08,0,0,0-9.38-18.81l-3.29-2.7a24.62,24.62,0,0,0-5.67-2.71c-3.18-1.2-6.87-2.23-10.3-2a28.54,28.54,0,0,0-19,8.8,27.28,27.28,0,0,0-7.33,17,27.23,27.23,0,0,0,1.69,10.53,24.55,24.55,0,0,0,5.57,8.89,25.7,25.7,0,0,0,8.3,6.22l3.91,1.49a25.7,25.7,0,0,0,8.26,1.24c.31,0-1.64-6.39-1.59-6.23-.5-1.62-1-3.22-1.61-4.82a1.93,1.93,0,0,0-.69-1.19c-1.43-.88-3-1.17-4.39-2a18.18,18.18,0,0,1-4.39-3.92,16.72,16.72,0,0,1-2.54-5.58,8.73,8.73,0,0,1-.61-3.76l.36-2.76.77-1.23a7,7,0,0,1,1.28-3.18,17.39,17.39,0,0,1,3.94-4.39,18.25,18.25,0,0,1,5.59-2.53c1.76-.57,4.81-1.12,6.52-.26l1.23.77,2.2.65a7.69,7.69,0,0,1,2.89,2c.85.84,1.71,1.66,2.59,2.47a16.15,16.15,0,0,1,3.37,10.87,4.13,4.13,0,0,1-.79,2.78l-.63,2.37c-.67,2-2.17,3.47-3.54,5a13,13,0,0,1-5,3.24,18.42,18.42,0,0,1-7.58,1.26,2.23,2.23,0,0,0,.8,1.23c1.24,1.34,2.49,2.64,3.78,3.92s2.56,2.57,3.89,3.81A2.21,2.21,0,0,0,265,565.5Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque2 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M266.55,567.49a10.9,10.9,0,0,0,4.62-1,9.32,9.32,0,0,0,3.06-1.43,24.11,24.11,0,0,0,6.89-6.19,29.16,29.16,0,0,0,5.7-19.54,29.89,29.89,0,0,0-9.67-19.65,29.5,29.5,0,0,0-9.24-5.59,25.76,25.76,0,0,0-10.39-2c-7.51.42-14.87,3.26-20,8.86a28.77,28.77,0,0,0-7.68,17.61,30.12,30.12,0,0,0,1.6,11.09,24.65,24.65,0,0,0,5.76,9.41,27.69,27.69,0,0,0,8.68,6.54l4.1,1.59a27.23,27.23,0,0,0,8.6,1.3c.29,0-1.87-4.81-1.81-4.68-.43-.91-1.34-3.86-2.48-4.36-2.06-.9-4.22-1.4-6.21-2.43a21.74,21.74,0,0,1-6.14-4.74,20,20,0,0,1-3.93-7,17.23,17.23,0,0,1-.39-10.87,20.7,20.7,0,0,1,15.58-15.17c2.61-.58,5.95-1,8.42,0s4.66,1.45,6.78,3a28.2,28.2,0,0,1,4.75,4.25,19.94,19.94,0,0,1,3.76,7.25A16.6,16.6,0,0,1,278,538.9l-.06,3.71-.71,1.81a13.19,13.19,0,0,1-1.5,4.33,19.89,19.89,0,0,1-4.32,5.92,20.29,20.29,0,0,1-4.69,3.07,17.27,17.27,0,0,1-7.85,1.59,1.58,1.58,0,0,0,.6,1.06q1.45,1.68,3,3.28c1,1.08,2,2.14,3.12,3.17A1.56,1.56,0,0,0,266.55,567.49Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                  <motion.path
                    variants={icon}
                    initial='hidden'
                    animate={flowerOpaque3 ? 'visible' : 'hidden'}
                    transition={{
                      default: { duration: 4, ease: 'easeInOut' },
                      fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                    }}
                    d='M267.65,571.38a16.53,16.53,0,0,0,6.6-1.83,22.76,22.76,0,0,0,6.16-4.6A29.45,29.45,0,0,0,287.19,554a31.14,31.14,0,0,0,.69-18.9,31.47,31.47,0,0,0-32-23.59c-14.64.91-27.5,13-28.77,27.75a30.82,30.82,0,0,0,4.34,18.85A30.43,30.43,0,0,0,249.93,572a29.15,29.15,0,0,0,9.52,1c.5,0,.3-2.72-.39-2.76l-4.29-.21a25.56,25.56,0,0,1-6.46-1.59,26.91,26.91,0,0,1-7.7-4,31,31,0,0,1-7.55-7.82l-2.1-4.09,49.51-29.51,3.39,10.69q4.75,6.24,3,12.87a16.24,16.24,0,0,1-1.08,4.23,26.55,26.55,0,0,1-4.88,9.08,31,31,0,0,1-5.72,5.25,22.88,22.88,0,0,1-9.23,3.44C265.1,568.79,267.12,571.47,267.65,571.38Z'
                    transform='translate(-131.82 -489.96)'
                    style={{ fill: '#fbb03b', opacity: 0.10910128185966073 }}
                  />
                </g>
              </g>
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M280.77,565.33a8.82,8.82,0,1,0-8.82-8.82A8.83,8.83,0,0,0,280.77,565.33Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f5ecbc', fillRule: 'evenodd' }}
              />
              <motion.path
                variants={icon}
                initial='hidden'
                animate={flowerOpaque1 ? 'visible' : 'hidden'}
                transition={{
                  default: { duration: 4, ease: 'easeInOut' },
                  fill: { duration: 4, ease: [1, 1, 0.8, 2] }
                }}
                d='M168.67,561.63a10.93,10.93,0,1,0-10.93-10.93A10.94,10.94,0,0,0,168.67,561.63Z'
                transform='translate(-131.82 -489.96)'
                style={{ fill: '#f5ecbc', fillRule: 'evenodd' }}
              />
            </g>
          </motion.svg>
        </SVGFlowerContainer>
      </div>
    );
  }
}
